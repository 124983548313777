import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import AtsAgentInputField from "./AtsAgentInputField";
import JWT from "expo-jwt";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import { ReczeeAtsAgentChip } from "../commons/ReczeeAtsAgent";
import amplitudeLogger from "../../utils/amplitude";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontSize: "18px",
  },
  buttonsContainer: {
    marginTop: "12px",
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function FilterCandidateSpreadsheet({ parsedPrompt, resetAction }) {
  const classes = useStyles();
  const [sources, setSources] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [recruiters, setRecruiters] = React.useState([]);
  const [requisitions, setRequisitions] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  React.useEffect(() => {
    console.log("Parsed Prompt", parsedPrompt);
    if (parsedPrompt && parsedPrompt["fields"]["sources_data"]) {
      setSources(parsedPrompt["fields"]["sources_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["states_data"]) {
      setStatuses(parsedPrompt["fields"]["states_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["recruiters_data"]) {
      setRecruiters(parsedPrompt["fields"]["recruiters_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["jobs_data"]) {
      setRequisitions(parsedPrompt["fields"]["jobs_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["tags_data"]) {
      setTags(parsedPrompt["fields"]["tags_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["start_date"]) {
      setStartDate(new Date(parsedPrompt["fields"]["start_date"]));
    }
    if (parsedPrompt && parsedPrompt["fields"]["end_date"]) {
      setEndDate(new Date(parsedPrompt["fields"]["end_date"]));
    }
  }, [parsedPrompt]);

  const handleSubmit = () => {
    let data = {
      sources: sources || [],
      statuses: statuses || [],
      round_filters: [[], [], [], []],
      tas: recruiters || [],
      requisitions: requisitions || [],
      candidate_application_tags: tags || [],
      min_experience: null,
      max_experience: null,
      start_date: startDate || null,
      end_date: endDate || null,
      include_archived: false,
    };

    const token = JWT.encode(data, "");
    window.open(`/candidates?navigation_token=${token}`);
  };

  return (
    <div className={classes.actionDataContainer}>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <AtsAgentInputField
            fieldName="source"
            fieldAttributes={{
              onChange: (_, values) => {
                setSources(values);
              },
              fullWidth: true,
              label: "Source",
              value: sources.length > 0 ? sources : [],
              single: false,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AtsAgentInputField
            fieldName="status"
            fieldAttributes={{
              onChange: (_, values) => {
                setStatuses(values);
              },
              fullWidth: true,
              label: "Status",
              value: statuses.length > 0 ? statuses : [],
              single: false,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AtsAgentInputField
            fieldName="recruiter"
            fieldAttributes={{
              onChange: (_, values) => {
                setRecruiters(values);
              },
              fullWidth: true,
              label: "TA Handler",
              value: recruiters.length > 0 ? recruiters : [],
              single: false,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AtsAgentInputField
            fieldName="requisition"
            fieldAttributes={{
              onChange: (_, values) => {
                setRequisitions(values);
              },
              fullWidth: true,
              label: "Job",
              value: requisitions.length > 0 ? requisitions : [],
              single: false,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AtsAgentInputField
            fieldName="tags"
            fieldAttributes={{
              onChange: (_, values) => {
                setTags(values);
              },
              fullWidth: true,
              label: "Tags",
              value: tags.length > 0 ? tags : [],
              single: false,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AtsAgentInputField
            fieldName="date"
            fieldAttributes={{
              onChange: (value) => {
                setStartDate(value);
              },
              label: "Start date",
              fullWidth: true,
              value: startDate,
              minDate: new Date(
                new Date().getTime() - 2 * 365 * 24 * 60 * 60 * 1000
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AtsAgentInputField
            fieldName="date"
            fieldAttributes={{
              onChange: (value) => {
                setEndDate(value);
              },
              label: "End date",
              fullWidth: true,
              value: endDate,
              maxDate: new Date(),
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.submit}
          size={"medium"}
          onClick={() => {
            resetAction(FilterCandidateSpreadsheetHandler.ACTION, "New Action");
          }}
        >
          New Action
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          className={classes.submit}
          color="primary"
          size={"medium"}
        >
          Show matching candidates
        </Button>
      </div>
    </div>
  );
}

export default class FilterCandidateSpreadsheetHandler extends BaseAgentHandler {
  static ACTION = "spreadsheet_view_navigation";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<FilterListOutlinedIcon color="primary" />}
        title={this.constructor.getHeaderText()}
      />
    );
  }

  static getHeaderText() {
    return "Filter specific candidates";
  }

  renderBody() {
    return (
      <FilterCandidateSpreadsheet
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
      />
    );
  }

  static renderChip(setInputValue, setExamplePrompt, onChipSelect) {
    return (
      <ReczeeAtsAgentChip
        startIcon={<FilterListOutlinedIcon style={{ color: "#D732A8" }} />}
        onClick={() => {
          setInputValue("Show candidates who ");
          setExamplePrompt(
            <>
              Show candidates who <b>applied via LinkedIn</b> <b>last week</b>.
            </>
          );
          onChipSelect(this.ACTION);
        }}
        headerText={this.getHeaderText()}
      />
    );
  }

  renderExamplePrompt() {}
}
