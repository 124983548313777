import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import AtsAgentInputField from "./AtsAgentInputField";
import { getRequest, postRequest } from "../../utils/httpHelpers";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { ReczeeAtsAgentChip } from "../commons/ReczeeAtsAgent";
import CalendarIntegration from "../commons/CalendarIntegration";
import Grid from "@material-ui/core/Grid";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import { formatToHHMM } from "../../utils/commonHelpers";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    // paddingBottom: theme.spacing(1),
    alignItems: "center",
    fontSize: "18px",
  },
  interviewsThisWeekText: {
    fontSize: "10px",
    marginLeft: theme.spacing(1),
    color: "#C62828",
  },
  buttonsContainer: {
    marginTop: "12px",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  overallContainer: {
    display: "flex",
    flexDirection: "column",
    // padding: theme.spacing(2),
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function SpecificScheduleComponent({
  parsedPrompt,
  resetAction,
  onActionComplete,
}) {
  const classes = useStyles();
  const [round, setRound] = React.useState(null);
  const [interviewer, setInterviewer] = React.useState(null);
  const [callStartTime, setCallStartTime] = React.useState(null);
  const [callEndTime, setCallEndTime] = React.useState(null);
  const [panelInterviewCounts, setPanelInterviewCounts] = React.useState({});
  const [candidateApplication, setCandidateApplication] = React.useState([]);
  const [calendarProvider, setCalendarProvider] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (parsedPrompt && parsedPrompt["fields"]["round_data"]) {
      setRound(parsedPrompt["fields"]["round_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["interviewer_data"]) {
      setInterviewer(parsedPrompt["fields"]["interviewer_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["candidate_application_data"]) {
      setCandidateApplication([
        parsedPrompt["fields"]["candidate_application_data"],
      ]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["start_time"]) {
      handleStartTimeChange(new Date(parsedPrompt["fields"]["start_time"]));
    }
    if (parsedPrompt && parsedPrompt["fields"]["end_time"]) {
      handleEndTimeChange(new Date(parsedPrompt["fields"]["end_time"]));
    }
  }, [parsedPrompt]);

  const panelCallbackOnOptionLoad = (options) => {
    let currDate = new Date().toISOString().split("T")[0];
    getRequest(
      "panel/interview-counts",
      { current_date: currDate, panel_ids: options.map((o) => o.id) },
      (response) => {
        setPanelInterviewCounts(response["result"]);
      },
      (errorMessages, statusCode) => {}
    );
  };

  const getInterviewsThisWeek = (option) => {
    return panelInterviewCounts[option.id] ? (
      <b className={classes.interviewsThisWeekText}>
        ({panelInterviewCounts[option.id]} interviews this week)
      </b>
    ) : (
      ""
    );
  };

  const handleStartTimeChange = (value) => {
    setCallStartTime(value);
    setCallEndTime(new Date(value.getTime() + 1 * 60 * 60 * 1000));
  };

  const handleEndTimeChange = (value) => {
    setCallEndTime(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (candidateApplication.length === 0) {
      enqueueSnackbar("Please select a candidate", reczeeAlertParams("error"));
      return;
    }

    setIsSubmitting(true);
    postRequest(
      "ats-agent/perform-action",
      {},
      {
        action: "specific_schedule",
        fields: {
          start_time: callStartTime,
          end_time: callEndTime,
          interviewer_data: interviewer,
          round_data: round,
          candidate_application_data: candidateApplication[0],
        },
        message: "",
      },
      (data) => {
        setIsSubmitting(false);
        const successData = {
          action: SpecificScheduleHandler.ACTION,
          title: "Interview scheduled successfully",
          description: (
            <>
              <strong>{round.name}</strong> interview for{" "}
              <strong>
                {candidateApplication[0]?.candidate?.name || "the candidate"}
              </strong>{" "}
              has been scheduled with <strong>{interviewer.name}</strong> on{" "}
              <strong>{callStartTime.toDateString()}</strong> from{" "}
              <strong>{formatToHHMM(callStartTime)}</strong> to{" "}
              <strong>{formatToHHMM(callEndTime)}</strong>.
            </>
          ),
          resetAction: resetAction,
          secondButton: (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              size={"medium"}
              target="_blank"
              component={Link}
              to={`/candidate-timeline/${candidateApplication[0].slug}`}
            >
              View Candidate Profile
            </Button>
          ),
        };
        onActionComplete(successData);
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
        }
      }
    );
  };

  return (
    <div className={classes.overallContainer}>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.actionDataContainer}>
          <div>
            <CalendarIntegration onProviderConnected={setCalendarProvider} />
          </div>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <AtsAgentInputField
                fieldName="candidate_application_data"
                fieldAttributes={{
                  label: "Candidate",
                  value: candidateApplication,
                  onChange: (_, values) => {
                    setCandidateApplication(values);
                    setRound(null);
                  },
                  fullWidth: true,
                  dataFetchPath: "candidate-application/recent-list",
                  dataFetchParams: {},
                  single: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="interviewer_data"
                fieldAttributes={{
                  value: interviewer,
                  label: "Interviewer",
                  fullWidth: true,
                  callbackOnOptionLoad: panelCallbackOnOptionLoad,
                  onChange: (_, value) => {
                    setInterviewer(value);
                  },
                  dataFetchPath: "users/list",
                  dataFetchParams: {
                    filter: [
                      "company_ta",
                      "company_admin",
                      "company_panel",
                      "company_hiring_manager",
                    ],
                  },
                  renderOption: (option) => (
                    <>
                      {option.name} {getInterviewsThisWeek(option)}
                    </>
                  ),
                  reRenderDependency: [round, candidateApplication],
                  single: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="round_data"
                fieldAttributes={{
                  value: round,
                  fullWidth: true,
                  onChange: (_, value) => {
                    setRound(value);
                  },
                  label: "Round",
                  single: true,
                  dataFetchPath:
                    candidateApplication &&
                    candidateApplication.length > 0 &&
                    candidateApplication[0].slug
                      ? `interview/allowed-rounds/${candidateApplication[0].slug}`
                      : "rounds",
                  reRenderDependency: [candidateApplication],
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="start_time"
                fieldAttributes={{
                  label: "Start time",
                  fullWidth: true,
                  value: callStartTime,
                  onChange: handleStartTimeChange,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="end_time"
                fieldAttributes={{
                  fullWidth: true,
                  label: "End time",
                  value: callEndTime,
                  onChange: handleEndTimeChange,
                  minDate: callStartTime,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.submit}
            size={"medium"}
            onClick={() => {
              resetAction(SpecificScheduleHandler.ACTION, "Cancel");
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            size={"medium"}
            disabled={isSubmitting || !calendarProvider}
          >
            {isSubmitting ? "Scheduling..." : "Schedule"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default class SpecificScheduleHandler extends BaseAgentHandler {
  static ACTION = "specific_schedule";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<TodayOutlinedIcon color="primary" />}
        title={this.constructor.getHeaderText()}
      />
    );
  }

  static getHeaderText() {
    return "Schedule an interview";
  }

  renderBody() {
    return (
      <SpecificScheduleComponent
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
        onActionComplete={this.onActionComplete}
      />
    );
  }

  static renderChip(setInputValue, setExamplePrompt, onChipSelect) {
    return (
      <ReczeeAtsAgentChip
        startIcon={
          <TodayOutlinedIcon fontSize="small" style={{ color: "#0F5DBC" }} />
        }
        onClick={() => {
          setInputValue("Schedule interview of [candidate] with ");
          setExamplePrompt(
            <>
              Schedule <b>30-minute Onsite round</b> of <b>John Doe</b> with{" "}
              <b>Taylor</b> on <b>10th Feb 10 am IST</b>
            </>
          );
          onChipSelect(this.ACTION);
        }}
        headerText={this.getHeaderText()}
      />
    );
  }
}
