import React from "react";
import amplitudeLogger, { SEARCH_PAGE } from "../../utils/amplitude";
import { makeStyles } from "@material-ui/core";
import CandidateApplicationStateBadge from "../candidate/CandidateApplicationStateBadge";

const useStyles = makeStyles((theme) => ({
  searchGroupRow: {
    padding: "6px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: (props) => {
        return props.noSelect ? "inherit" : "rgba(0, 0, 0, 0.04)";
      },
      borderRadius: "2px",
    },
  },
  firstCandidateRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  candidateListRequisition: {
    fontSize: "12px",
  },
  candidateListMetadata: {
    fontSize: "12px",
    color: "darkslategray",
  },
}));

export default function CandidateApplicationDropdownRow(props) {
  const classes = useStyles(props);
  const row = props.row;
  const noSelect = props.noSelect;

  return (
    <div className={classes.searchGroupRow} onMouseDown={props.onMouseDown}>
      <div className={classes.firstCandidateRow}>
        <div>
          <b className={classes.candidateListName}>{row.candidate.name} </b>
          <span>
            <CandidateApplicationStateBadge {...row.state} small={true} />
          </span>
        </div>
        <span className={classes.candidateListMetadata}>
          <i>via</i> <b>{row.source}</b>
        </span>
      </div>
      <div className={classes.secondCandidateRow}>
        <span className={classes.candidateListRequisition}>
          {row.requisition_title}
        </span>
      </div>
    </div>
  );
}
