import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import AtsAgentInputField from "./AtsAgentInputField";
import { ReczeeAtsAgentChip } from "../commons/ReczeeAtsAgent";
import CalendarIntegration from "../commons/CalendarIntegration";
import { getRequest, postRequest } from "../../utils/httpHelpers";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { popularTimezones } from "../../constants";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import { reczeeAlertParams, formatToHHMM } from "../../utils/commonHelpers";
import LinkIcon from "@material-ui/icons/Link";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontSize: "18px",
  },
  interviewsThisWeekText: {
    fontSize: "10px",
    marginLeft: theme.spacing(1),
    color: "#C62828",
  },
  buttonsContainer: {
    marginTop: "12px",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  overallContainer: {
    display: "flex",
    flexDirection: "column",
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function SelfScheduleComponent({
  parsedPrompt,
  resetAction,
  onActionComplete,
}) {
  const classes = useStyles();
  const [round, setRound] = React.useState(null);
  const [interviewer, setInterviewer] = React.useState(null);
  const [candidateApplication, setCandidateApplication] = React.useState([]);

  const [duration, setDuration] = React.useState(null);
  const [calendarProvider, setCalendarProvider] = React.useState(null);
  const [durationOptions, setdurationOptions] = React.useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [panelInterviewCounts, setPanelInterviewCounts] = React.useState({});

  const [workingHoursStart, setWorkingHoursStart] = React.useState(
    new Date("1970-01-01T09:00:00")
  );
  const [workingHoursEnd, setWorkingHoursEnd] = React.useState(
    new Date("1970-01-01T18:00:00")
  );

  // Timezone selection
  // Determine guessedTimeZone from browser
  const guessedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultTimezone =
    popularTimezones.find((tz) => tz.value === guessedTimezone) ||
    popularTimezones[0];

  const [selectedTimezone, setSelectedTimezone] =
    React.useState(defaultTimezone);
  const [panelTimezoneOffset, setPanelTimezoneOffset] = React.useState(
    -moment().tz(selectedTimezone.value).utcOffset()
  );
  const [panelTimezoneAbbreviation, setPanelTimezoneAbbreviation] =
    React.useState(selectedTimezone.abbreviation);

  const handleTimezoneChange = (newValue) => {
    if (newValue) {
      setSelectedTimezone(newValue);
    }
  };

  React.useEffect(() => {
    setPanelTimezoneAbbreviation(selectedTimezone.abbreviation);
    setPanelTimezoneOffset(-moment().tz(selectedTimezone.value).utcOffset());
  }, [selectedTimezone]);

  React.useEffect(() => {
    if (parsedPrompt && parsedPrompt["fields"]["round_data"]) {
      setRound(parsedPrompt["fields"]["round_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["interviewer_data"]) {
      setInterviewer(parsedPrompt["fields"]["interviewer_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["candidate_application_data"]) {
      setCandidateApplication([
        parsedPrompt["fields"]["candidate_application_data"],
      ]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["timezone"]) {
      const foundTimezone = popularTimezones.find(
        (tz) => tz.abbreviation === parsedPrompt["fields"]["timezone"]
      );
      if (foundTimezone) {
        handleTimezoneChange(foundTimezone);
      }
    }
    if (parsedPrompt && parsedPrompt["fields"]["working_hours_start"]) {
      const [hh, mm] = parsedPrompt["fields"]["working_hours_start"].split(":");
      const localDate = new Date(1970, 0, 1, Number(hh), Number(mm));
      setWorkingHoursStart(localDate);
    }
    if (parsedPrompt && parsedPrompt["fields"]["working_hours_end"]) {
      const [hh, mm] = parsedPrompt["fields"]["working_hours_end"].split(":");
      const localDate = new Date(1970, 0, 1, Number(hh), Number(mm));
      setWorkingHoursEnd(localDate);
    }
  }, [parsedPrompt]);

  React.useEffect(() => {
    let tempDurationOptions = [];
    if (calendarProvider === "outlook") {
      tempDurationOptions = [
        { label: "30 mins", key: 30 },
        { label: "60 mins", key: 60 },
      ];
    } else {
      tempDurationOptions = [
        { label: "15 mins", key: 15 },
        { label: "30 mins", key: 30 },
        { label: "45 mins", key: 45 },
        { label: "60 mins", key: 60 },
      ];
    }
    setdurationOptions(tempDurationOptions);

    if (parsedPrompt["fields"]["duration"]) {
      if (
        tempDurationOptions.find(
          (option) => option.key === parsedPrompt["fields"]["duration"]
        )
      ) {
        setDuration(parsedPrompt["fields"]["duration"]);
      } else {
        setDuration(null);
      }
    }
  }, [calendarProvider]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (candidateApplication.length === 0) {
      enqueueSnackbar("Please select a candidate!", reczeeAlertParams("error"));
      return;
    }

    if (workingHoursStart >= workingHoursEnd) {
      enqueueSnackbar(
        "Please select a proper availability range.",
        reczeeAlertParams("error")
      );
      return;
    }
    setIsSubmitting(true);

    postRequest(
      "ats-agent/perform-action",
      {},
      {
        action: "self_schedule",
        fields: {
          interviewer_data: interviewer,
          round_data: round,
          candidate_application_data: candidateApplication[0],
          duration: duration,
          working_hours_start: formatToHHMM(workingHoursStart),
          working_hours_end: formatToHHMM(workingHoursEnd),
          timezone_offset: panelTimezoneOffset,
        },
        message: "",
      },
      (data) => {
        setIsSubmitting(false);
        const successData = {
          action: SelfScheduleHandler.ACTION,
          title: "Self schedule link sent successfully!",
          description: (
            <>
              Self Schedule link has been sent to{" "}
              <strong>
                {candidateApplication[0]?.candidate?.name || "the candidate"}
              </strong>{" "}
              for the <strong>{round?.name}</strong> interview with{" "}
              <strong>{interviewer?.name}</strong> (working hours:{" "}
              <strong>
                {formatToHHMM(workingHoursStart)} -{" "}
                {formatToHHMM(workingHoursEnd)} {panelTimezoneAbbreviation}
              </strong>
              ).
            </>
          ),
          resetAction: resetAction,
          secondButton: (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              size={"medium"}
              target="_blank"
              component={Link}
              to={`/candidate-timeline/${candidateApplication[0].slug}`}
            >
              View Candidate Profile
            </Button>
          ),
        };
        onActionComplete(successData);
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
        }
      }
    );
  };

  const getInterviewsThisWeek = (option) => {
    return panelInterviewCounts[option.id] ? (
      <b className={classes.interviewsThisWeekText}>
        ({panelInterviewCounts[option.id]} interviews this week)
      </b>
    ) : (
      ""
    );
  };

  const panelCallbackOnOptionLoad = (options) => {
    let currDate = new Date().toISOString().split("T")[0];
    getRequest(
      "panel/interview-counts",
      { current_date: currDate, panel_ids: options.map((o) => o.id) },
      (response) => {
        setPanelInterviewCounts(response["result"]);
      },
      (errorMessages, statusCode) => {}
    );
  };

  return (
    <div className={classes.overallContainer}>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.actionDataContainer}>
          <div>
            <CalendarIntegration onProviderConnected={setCalendarProvider} />
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AtsAgentInputField
                fieldName="candidate_application_data"
                fieldAttributes={{
                  value: candidateApplication,
                  label: "Candidate",
                  onChange: (_, values) => {
                    setCandidateApplication(values);
                    setRound(null);
                  },
                  dataFetchPath: "candidate-application/recent-list",
                  dataFetchParams: {},
                  single: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="interviewer_data"
                fieldAttributes={{
                  value: interviewer,
                  label: "Interviewer",
                  fullWidth: true,
                  callbackOnOptionLoad: panelCallbackOnOptionLoad,
                  onChange: (_, value) => {
                    setInterviewer(value);
                  },
                  dataFetchPath: "users/list",
                  dataFetchParams: {
                    filter: [
                      "company_ta",
                      "company_admin",
                      "company_panel",
                      "company_hiring_manager",
                    ],
                  },
                  renderOption: (option) => (
                    <>
                      {option.name} {getInterviewsThisWeek(option)}
                    </>
                  ),
                  reRenderDependency: [round, candidateApplication],
                  single: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="round_data"
                fieldAttributes={{
                  value: round,
                  label: "Round",
                  onChange: (_, value) => {
                    setRound(value);
                  },
                  single: true,
                  dataFetchPath:
                    candidateApplication &&
                    candidateApplication.length > 0 &&
                    candidateApplication[0].slug
                      ? `interview/allowed-rounds/${candidateApplication[0].slug}`
                      : "rounds",
                  reRenderDependency: [candidateApplication],
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="duration"
                fieldAttributes={{
                  label: "Duration",
                  value:
                    durationOptions.find((option) => option.key === duration) ||
                    null,
                  durationOptions: durationOptions,
                  onChange: (_, value) => {
                    setDuration(value ? value.key : null);
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="timezone"
                fieldAttributes={{
                  fullWidth: true,
                  label: "Timezone",
                  value: selectedTimezone,
                  onChange: handleTimezoneChange,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="working_hours_start"
                fieldAttributes={{
                  label: "Working hours start",
                  value: workingHoursStart,
                  fullWidth: true,
                  onChange: (value) => {
                    setWorkingHoursStart(value);
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="working_hours_end"
                fieldAttributes={{
                  label: "Working hours end",
                  fullWidth: true,
                  value: workingHoursEnd,
                  onChange: (value) => {
                    setWorkingHoursEnd(value);
                  },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.submit}
            size={"medium"}
            onClick={() => {
              resetAction(SelfScheduleHandler.ACTION, "Cancel");
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            size={"medium"}
            disabled={isSubmitting || !calendarProvider}
          >
            {isSubmitting ? "Please wait..." : "Send self-schedule link"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default class SelfScheduleHandler extends BaseAgentHandler {
  static ACTION = "self_schedule";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<LinkIcon color="primary" />}
        title={this.constructor.getHeaderText()}
      />
    );
  }

  renderBody() {
    return (
      <SelfScheduleComponent
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
        onActionComplete={this.onActionComplete}
      />
    );
  }

  static getHeaderText() {
    return "Send self-scheduling link to";
  }

  static renderChip(setInputValue, setExamplePrompt, onChipSelect) {
    return (
      <ReczeeAtsAgentChip
        startIcon={<LinkIcon style={{ color: "#0F5DBC" }} />}
        onClick={() => {
          setInputValue("Send self-scheduling link to ");
          setExamplePrompt(
            <>
              Send self-scheduling link to <b>Rohit</b> for the{" "}
              <b>Onsite round</b> with <b>John Doe</b> (30 minutes). His working
              hours: 2-5pm.
            </>
          );
          onChipSelect(this.ACTION);
        }}
        headerText={this.getHeaderText()}
      />
    );
  }

  renderExamplePrompt() {}
}
