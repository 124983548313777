import { makeStyles } from "@material-ui/core/styles";
import StarsIcon from "@material-ui/icons/Stars";
import React from "react";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  starIcon: {
    color: "orange",
    fontSize: "16px",
    marginLeft: "4px",
    cursor: "pointer",
  },
}));

export default function PremiumFeatureStarIcon(props) {
  const classes = useStyles();

  if (props.tooltip) {
    return (
      <Tooltip title={props.tooltip} placement="right">
        <StarsIcon className={classes.starIcon} />
      </Tooltip>
    );
  } else {
    return <StarsIcon className={classes.starIcon} />;
  }
}
