import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import DangerousHtml from "../commons/DangerousHtml";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { postRequest } from "../../utils/httpHelpers";
import { reczeeAlertParams } from "../../utils/commonHelpers";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontSize: "18px",
  },
  buttonsContainer: {
    marginTop: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    display: "flex",
  },
  overallContainer: {
    display: "flex",
    flexDirection: "column",
  },
  errorMessage: {
    textAlign: "center",
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function GenericHandlerComponent({ parsedPrompt, resetAction }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={classes.overallContainer}>
      <div className={classes.actionDataContainer}>
        {parsedPrompt.message ? (
          <DangerousHtml content={parsedPrompt.message} />
        ) : (
          <div className={classes.errorMessage}>
            Sorry, we could not find the result you were looking for! Please
            click on <b>Contact Support</b> if you would like us to implement
            this feature.
          </div>
        )}
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.submit}
          size={"medium"}
          onClick={() => {
            resetAction(
              GenericHandler.ACTION,
              parsedPrompt.message ? "Cancel" : "New Action"
            );
          }}
        >
          {parsedPrompt.message ? "Cancel" : "New Action"}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          size={"medium"}
          onClick={() => {
            if (parsedPrompt.message) {
              resetAction(
                GenericHandler.ACTION,
                parsedPrompt.message ? "Looks good" : "Contact Support"
              );
            } else {
              postRequest(
                "ats-agent/request-callback",
                { kind: "ats_agent_generic", prompt: parsedPrompt.prompt },
                {},
                (data) => {
                  enqueueSnackbar(
                    "We have been notified and will reach out shortly!",
                    reczeeAlertParams("success")
                  );
                },
                (errorMessages) => {
                  enqueueSnackbar(
                    "Something went wrong!",
                    reczeeAlertParams("error")
                  );
                }
              );
            }
          }}
        >
          {parsedPrompt.message ? "Looks good" : "Contact Support"}
        </Button>
      </div>
    </div>
  );
}

export default class GenericHandler extends BaseAgentHandler {
  static ACTION = "generic";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<InfoOutlinedIcon color="primary" />}
        title="General information"
      />
    );
  }

  renderBody() {
    return (
      <GenericHandlerComponent
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
      />
    );
  }
}
