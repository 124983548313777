import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import AtsAgentInputField from "./AtsAgentInputField";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { ReczeeAtsAgentChip } from "../commons/ReczeeAtsAgent";
import { postRequest } from "../../utils/httpHelpers";
import { NotificationContext } from "../../contexts/NotificationContext";
import { FeatureFlag } from "../../utils/featureFlagHelpers";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontSize: "18px",
  },
  buttonsContainer: {
    marginTop: "12px",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  overallContainer: {
    display: "flex",
    flexDirection: "column",
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function InviteUserComponent({ parsedPrompt, resetAction, onActionComplete }) {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [designation, setDesignation] = React.useState(null);
  const [roles, setRoles] = React.useState([]);

  const [notificationInfo] = React.useContext(NotificationContext);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (parsedPrompt && parsedPrompt["fields"]["email"]) {
      setEmail(parsedPrompt["fields"]["email"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["designation_data"]) {
      setDesignation(parsedPrompt["fields"]["designation_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["roles_data"]) {
      let rolesFromPrompt = Array.isArray(parsedPrompt["fields"]["roles_data"])
        ? parsedPrompt["fields"]["roles_data"]
        : [parsedPrompt["fields"]["roles_data"]];

      rolesFromPrompt = rolesFromPrompt.filter(
        (role) => role.value !== "reczee_admin" && role.value !== "agency"
      );
      setRoles(rolesFromPrompt);
    }
  }, [parsedPrompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    postRequest(
      "ats-agent/perform-action",
      {},
      {
        action: "invite_user",
        fields: {
          email: email,
          roles: roles.map((role) => role.value),
          designation_data: designation,
        },
        message: "",
      },
      (data) => {
        setIsSubmitting(false);
        const successData = {
          action: InviteUserHandler.ACTION,
          title: "Invite sent successfully",
          description: (
            <>
              Invite has been sent to <strong>{email}</strong> with designation{" "}
              <strong>{designation.title}</strong> and roles{" "}
              <strong>{roles.map((r) => r.title).join(", ")}</strong>.
            </>
          ),
          resetAction: resetAction,
          secondButton: (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              size={"medium"}
              target="_blank"
              component={Link}
              to={`/company-settings/users`}
            >
              Manage Users
            </Button>
          ),
        };
        onActionComplete(successData);
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
        }
      }
    );
  };

  const referralModuleActive = FeatureFlag.is_enabled(
    notificationInfo,
    "company",
    "referral_module"
  );

  return (
    <div className={classes.overallContainer}>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.actionDataContainer}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="email"
                fieldAttributes={{
                  value: email,
                  label: "Email",
                  fullWidth: true,
                  onChange: (e) => setEmail(e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="designation"
                fieldAttributes={{
                  label: "Designation",
                  required: true,
                  value: designation,
                  fullWidth: true,
                  onChange: (_, newVal) => setDesignation(newVal),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="roles"
                fieldAttributes={{
                  value: roles,
                  label: "Role",
                  required: true,
                  fullWidth: true,
                  onChange: (_, vals) => setRoles(vals),
                  referralModuleActive: referralModuleActive,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.submit}
            size={"medium"}
            onClick={() => {
              resetAction(InviteUserHandler.ACTION, "Cancel");
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            size={"medium"}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Please wait..." : "Invite User"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default class InviteUserHandler extends BaseAgentHandler {
  static ACTION = "invite_user";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<PersonAddOutlinedIcon color="primary" />}
        title={this.constructor.getHeaderText()}
      />
    );
  }

  static getHeaderText() {
    return "Invite User to Reczee";
  }

  renderBody() {
    return (
      <InviteUserComponent
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
        onActionComplete={this.onActionComplete}
      />
    );
  }

  static renderChip(setInputValue, setExamplePrompt, onChipSelect) {
    return (
      <ReczeeAtsAgentChip
        startIcon={
          <PersonAddOutlinedIcon
            fontSize="small"
            style={{ color: "#009951" }}
          />
        }
        onClick={() => {
          setInputValue("Invite ");
          setExamplePrompt(
            <>
              Invite <b>John Doe (Engineering manager)</b> as a <b>Superuser</b>
            </>
          );
          onChipSelect(this.ACTION);
        }}
        headerText={this.getHeaderText()}
      />
    );
  }
}
