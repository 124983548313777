import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  ReczeeAutoComplete,
  ReczeeTimezonePicker,
  ReczeeInputField,
} from "../commons/ReczeeInputField";
import { getRequest } from "../../utils/httpHelpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import React from "react";
import CandidateApplicationStateBadge from "../candidate/CandidateApplicationStateBadge";
import { COMPANY_ROLES } from "../../constants";
import PremiumFeatureStarIcon from "../company/PremiumFeatureStarIcon";
import { RequisitionTitleWithState } from "../requisitions/RequisitionStateBadge";
import CandidateApplicationPreview from "./CandidateApplicationPreview";
import { TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputField: {
    // width: "220px",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    "& .MuiInputBase-root": {
      // minHeight: "50px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#333333",
    },
    "& label": {
      color: "#333333",
      // paddingLeft: "20px",
    },
  },
  candidateApplicationField: {
    // width: "280px",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0px",
  },
  dateInputField: {
    "& label": {
      // paddingLeft: "22px",
    },
  },
  searchResultContainer: {
    zIndex: 10,
    backgroundColor: "white",
    position: "absolute",
    right: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: "4px", // Same as standard paper component
    color: "black",
    maxWidth: "450px",
    minWidth: "360px",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
  },
  searchGroupHeading: {
    fontSize: "16px",
  },
  searchGroupRows: {
    paddingTop: "4px",
    fontSize: "14px",
  },
  searchGroupRow: {
    padding: "6px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      borderRadius: "2px",
    },
  },
  candidateListRequisition: {
    fontSize: "12px",
  },
  candidateListMetadata: {
    fontSize: "12px",
    color: "darkslategray",
  },
  firstCandidateRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterInputField: {
    textAlign: "left",
    // width: "300px",
    "& .MuiChip-root": {
      height: "24px",
      backgroundColor: "#225dbcb8",
      color: "white",
      fontWeight: "bold",
    },
    "& .MuiChip-deleteIcon": {
      width: "18px",
      color: "#ffffffb3",
    },
    "& .MuiInputBase-formControl": {
      // minHeight: "50px",
    },
  },
  datepickerField: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    // width: "220px",
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // minHeight: "50px",
    // paddingLeft: "2px",
    // paddingRight: "2px",
    "& .MuiOutlinedInput-adornedEnd": {
      // paddingRight: "8px",
    },
    "& .MuiIconButton-root": {
      padding: "4px",
    },
  },
}));

function ReczeeCustomAutoComplete(props) {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [optionValues, setOptionValues] = React.useState(props.values || []);
  const dataFetchPath = props.dataFetchPath;
  const dataFetchParams = props.dataFetchParams || {};
  const responseMapper = props.responseMapper || ((response) => response);
  const dependencies = props.reRenderDependency ? props.reRenderDependency : [];
  let finalSortedValues = optionValues;

  if (props.sortBy) {
    finalSortedValues = optionValues.sort(props.sortBy);
  }

  React.useEffect(() => {
    if (!props.disabled && dataFetchPath) {
      getRequest(
        dataFetchPath,
        dataFetchParams,
        (data) => {
          let responseOptions = responseMapper(data);
          if (responseOptions.length === 0 && props.valuesOnEmptyResponse) {
            responseOptions = props.valuesOnEmptyResponse;
          }
          setOptionValues(responseOptions);
          if (props.callbackOnOptionLoad) {
            props.callbackOnOptionLoad(responseOptions);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    // @Todo(Raj): Fix this?
    // @Todo(Raj): adding props.dataFetchParams for candidate add page calls
    //             pipelines endpoint multiple times on changing designations
    // eslint-disable-next-line
  }, dependencies);

  React.useEffect(() => {
    setOptionValues(props.values || []);
  }, [props.values]);

  React.useEffect(() => {
    setSelectedValues(props.value);
  }, [props.value]);

  let inputValue = props.value;
  // Use preSelectOnlyOne carefully. Only use it for the inputs which have
  // controlled input (one with value guided with state variable)
  if (
    !props.value &&
    props.preSelectOnlyOne &&
    finalSortedValues.length === 1
  ) {
    inputValue = finalSortedValues[0];
    props.onChange(null, inputValue);
  }

  const handleChange = (event, newValue) => {
    if (props.single) {
      setSelectedValues(newValue.slice(-1));
    } else {
      setSelectedValues(newValue);
    }

    // Call the parent's onChange handler if provided
    if (props.onChange) {
      props.onChange(event, props.single ? newValue.slice(-1) : newValue);
    }
  };

  return (
    <Autocomplete
      size={props.size}
      multiple
      className={
        props.className
          ? `${props.className} ${classes.autocompleteField}`
          : classes.autocompleteField
      }
      onChange={handleChange}
      options={finalSortedValues}
      groupBy={props.groupBy}
      disabled={props.disabled}
      filterSelectedOptions={false}
      disableCloseOnSelect={props.disableCloseOnSelect}
      getOptionLabel={props.getOptionLabel}
      disableClearable={props.disableClearable}
      limitTags={props.limitTags || 10}
      clearOnBlur={props.clearOnBlur}
      fullWidth={props.fullWidth}
      filterOptions={props.filterOptions}
      value={selectedValues}
      freeSolo={props.freeSolo}
      selectOnFocus={props.selectOnFocus}
      renderOption={props.renderOption}
      getOptionSelected={
        props.getOptionSelected
          ? props.getOptionSelected
          : (option, value) => value && option.id === value.id
      }
      renderTags={(selected, getTagProps) => {
        if (props.single) {
          const lastSelected = selected[selected.length - 1];
          return (
            lastSelected && (
              <CandidateApplicationPreview noSelect={true} row={lastSelected} />
            )
          );
        }
        return selected.map((option, index) => (
          <CandidateApplicationPreview noSelect={true} row={option} />
        ));
      }}
      renderInput={(params) => (
        <>
          {props.title && (
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "normal" }}
              color={params.disabled ? "textSecondary" : "initial"}
            >
              {props.title}
              {props.required && (
                <Typography
                  component="span"
                  color={params.disabled ? "textSecondary" : "error"}
                >
                  *
                </Typography>
              )}
            </Typography>
          )}
          <TextField
            {...params}
            variant={props.variant ? props.variant : "outlined"}
            label={props.label}
            placeholder={props.placeholder}
            className={props.inputFieldClassName}
            helperText={props.helperText ? props.helperText : ""}
          />
        </>
      )}
    />
  );
}

export default function AtsAgentInputField({ fieldName, fieldAttributes }) {
  const classes = useStyles();
  switch (fieldName) {
    case "start_time":
    case "end_time":
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            size="small"
            inputVariant="outlined"
            className={`${classes.inputField} ${classes.dateInputField}`}
            minutesStep={5}
            title="Time"
            // disablePast={callType !== "external_interview"}
            ampm={false}
            {...fieldAttributes}
          />
        </MuiPickersUtilsProvider>
      );
    case "working_hours_start":
    case "working_hours_end":
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            size="small"
            inputVariant="outlined"
            className={`${classes.inputField} ${classes.dateInputField}`}
            minutesStep={5}
            ampm={false}
            {...fieldAttributes}
          />
        </MuiPickersUtilsProvider>
      );
    case "timezone":
      return (
        <ReczeeTimezonePicker
          variant="outlined"
          size="small"
          className={classes.inputField}
          fullWidth={false}
          required={true}
          value={fieldAttributes.value}
          {...fieldAttributes}
        />
      );
    case "duration":
      return (
        <ReczeeAutoComplete
          variant="outlined"
          size="small"
          single
          fullWidth={false}
          required={true}
          className={`${classes.inputField} ${classes.filterInputField}`}
          value={fieldAttributes.value}
          values={fieldAttributes.durationOptions || []}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, val) => option.key === val.key}
          {...fieldAttributes}
        />
      );
    case "candidate_application_data":
      return (
        <ReczeeCustomAutoComplete
          variant="outlined"
          size="small"
          single={true}
          className={`${classes.inputField} ${classes.candidateApplicationField}`}
          fullWidth={false}
          required={true}
          responseMapper={(response) => response.candidate_applications}
          renderOption={(option) => (
            <CandidateApplicationPreview row={option} noSelect={true} />
          )}
          getOptionLabel={(option) => option.candidate.name}
          {...fieldAttributes}
        />
      );
    case "template_data":
      return (
        <ReczeeAutoComplete
          // title="Round"
          fullWidth={false}
          variant="outlined"
          required={true}
          dataFetchPath="text-templates"
          size="small"
          className={`${classes.inputField} ${classes.filterInputField}`}
          responseMapper={(response) => response}
          getOptionLabel={(option) => option.name}
          {...fieldAttributes}
        />
      );
    case "interviewer_data":
      return (
        <ReczeeAutoComplete
          // single
          // fullWidth
          variant="outlined"
          size="small"
          fullWidth={false}
          className={`${classes.inputField} ${classes.filterInputField}`}
          // disabled={!interviewRound}
          // reRenderDependency={[interviewRound, candidateAvailabilitySlot]}
          // onChange={handlePanelChange(candidateAvailabilitySlot)}
          // title="Panel"
          required={true}
          // callbackOnOptionLoad={panelCallbackOnOptionLoad}
          // value={stateValue}
          responseMapper={(response) => response.users}
          // dataFetchPath={`interview/allowed-panels/${props.candidateApplicationSlug}`}
          // dataFetchParams={{
          //   interview_round_id: interviewRound ? interviewRound.id : "0",
          // }}
          getOptionLabel={(option) => option.name}
          {...fieldAttributes}
          // renderOption={(option) => (
          //   <>
          //     {option.name} {getInterviewsThisWeek(option)}
          //   </>
          // )}
        />
      );
    case "round_data":
      return (
        <ReczeeAutoComplete
          fullWidth={false}
          variant="outlined"
          required={true}
          size="small"
          className={`${classes.inputField} ${classes.filterInputField}`}
          responseMapper={(response) => response.rounds}
          getOptionLabel={(option) => option.name}
          {...fieldAttributes}
        />
      );
    case "agency_name":
    case "email":
      return (
        <ReczeeInputField
          fullWidth={false}
          variant="outlined"
          required={true}
          size="small"
          type="text"
          className={classes.inputField}
          value={fieldAttributes.value}
          helperText={fieldAttributes.helperText}
          {...fieldAttributes}
        />
      );
    case "designation":
      return (
        <ReczeeAutoComplete
          single={true}
          fullWidth={false}
          variant="outlined"
          required={true}
          size="small"
          className={`${classes.inputField} ${classes.filterInputField}`}
          dataFetchPath="designations"
          responseMapper={(response) => response}
          getOptionLabel={(option) => option.title}
          value={fieldAttributes.value}
          onChange={(_, newValue) => fieldAttributes.onChange(_, newValue)}
          {...fieldAttributes}
        />
      );
    case "roles":
      return (
        <ReczeeAutoComplete
          multiple
          fullWidth={false}
          variant="outlined"
          required={true}
          size="small"
          className={`${classes.inputField} ${classes.filterInputField}`}
          value={fieldAttributes.value}
          onChange={(_, newValues) => {
            fieldAttributes.onChange(_, newValues);
          }}
          values={Object.keys(COMPANY_ROLES)
            .filter((role) => role !== "reczee_admin" && role !== "agency")
            .map((role) => {
              return {
                title: COMPANY_ROLES[role],
                value: role,
              };
            })}
          getOptionSelected={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.title}
          renderOption={(option) =>
            option.value === "company_employee" &&
            !fieldAttributes.referralModuleActive ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {option.title} <PremiumFeatureStarIcon />
              </div>
            ) : (
              option.title
            )
          }
          {...fieldAttributes}
        />
      );
    case "source":
      return (
        <ReczeeAutoComplete
          size="small"
          // onChange={(_, values) => {
          //   setCandidateFilters({
          //     ...candidateFilters,
          //     sources: values,
          //   });
          // }}
          variant="outlined"
          showSelectedOptions={false}
          // value={candidateFilters.sources || []}
          dataFetchPath="candidate-application/get-allowed-sources"
          responseMapper={(response) => response.sources}
          renderOption={(option) => option.title}
          className={`${classes.inputField} ${classes.filterInputField}`}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) =>
            value && value.value === option.value
          }
          {...fieldAttributes}
        />
      );
    case "status":
      return (
        <ReczeeAutoComplete
          size="small"
          className={`${classes.inputField} ${classes.filterInputField}`}
          // values={nextStateDetails}
          // onChange={(_, values) => {
          //   setCandidateFilters({
          //     ...candidateFilters,
          //     statuses: values,
          //   });
          // }}
          // value={candidateFilters.statuses || []}
          variant="outlined"
          dataFetchPath="candidate-application/get-next-states"
          responseMapper={(response) => response.next_states}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) =>
            value && value.state === option.state
          }
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
              return (
                <div key={index}>
                  <CandidateApplicationStateBadge
                    {...option}
                    {...getTagProps({ index })}
                  />
                </div>
              );
            });
          }}
          renderOption={(option) => (
            <CandidateApplicationStateBadge
              style={{ margin: "4px" }}
              {...option}
            />
          )}
          {...fieldAttributes}
        />
      );
    case "recruiter":
      return (
        <ReczeeAutoComplete
          size="small"
          className={`${classes.inputField} ${classes.filterInputField}`}
          // onChange={(_, values) => {
          //   setCandidateFilters({
          //     ...candidateFilters,
          //     tas: values,
          //   });
          // }}
          variant="outlined"
          dataFetchPath="users/list"
          // value={candidateFilters.tas || []}
          responseMapper={(response) => response.users}
          dataFetchParams={{
            filter: ["company_ta", "company_admin"],
          }}
          getOptionLabel={(option) => option.name}
          {...fieldAttributes}
        />
      );
    case "requisition":
      return (
        <ReczeeAutoComplete
          className={`${classes.inputField} ${classes.filterInputField}`}
          size="small"
          // onChange={(_, values) => {
          //   setCandidateFilters({
          //     ...candidateFilters,
          //     requisitions: values,
          //   });
          // }}
          // value={candidateFilters.requisitions || []}
          variant="outlined"
          dataFetchPath="requisitions"
          dataFetchParams={{ page: "spreadsheet-view" }}
          responseMapper={(response) => response.requisitions}
          renderOption={(option) => (
            <RequisitionTitleWithState
              title={option.title}
              state={option.state}
            />
          )}
          getOptionLabel={(option) => option.title}
          {...fieldAttributes}
        />
      );
    case "tags":
      return (
        <ReczeeAutoComplete
          className={`${classes.inputField} ${classes.filterInputField}`}
          variant="outlined"
          size="small"
          // onChange={(_, values) => {
          //   setCandidateFilters({
          //     ...candidateFilters,
          //     candidate_application_tags: values,
          //   });
          // }}
          // value={candidateFilters.candidate_application_tags || []}
          dataFetchPath="ats-tags"
          dataFetchParams={{ order_by: "id" }}
          getOptionLabel={(option) => option.title}
          {...fieldAttributes}
        />
      );
    case "date":
      return (
        // <div className={classes.datepickerField}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            size="small"
            // value={candidateFilters.start_date || null}
            // onChange={(val) => {
            //   setCandidateFilters({
            //     ...candidateFilters,
            //     start_date: val,
            //   });
            // }}
            className={`${classes.inputField} ${classes.dateInputField}`}
            inputVariant="outlined"
            format="dd-MM-yyyy"
            placeholder="DD-MM-YYYY"
            disableFuture
            {...fieldAttributes}
          />
        </MuiPickersUtilsProvider>
        // </div>
      );
    default:
      break;
  }
}
