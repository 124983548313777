import { Button, CircularProgress, Card, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  reczeeAlertParams,
  deepMergeJSON,
  isIndia,
} from "../../utils/commonHelpers";
import { getRequest, multiPartPostRequest } from "../../utils/httpHelpers";
import {
  CTC_NP_REQUIRED_COMPANIES,
  CTC_REQUIRED_COMPANIES,
} from "../../utils/featureFlagHelpers";
import { getQueryParams } from "../../utils/urlHelpers";
import {
  ReczeeFileDropInputField,
  ReczeeCheckBox,
  ReczeeAutoComplete,
} from "../commons/ReczeeInputField";
import { CareersPageCurrencyPicker } from "../careers/CareersPageInputField";
import ReczeeJobsPageNavbar from "../commons/ReczeeJobsPageNavbar";
import { ReczeeJobsFooter } from "./ReczeeJobsFooter";
import { CareersPageInputField } from "../careers/CareersPageInputField";
import CareersPageScreeningQuestionInput from "../careers/CareersPageScreeningQuestionInput";
import { JOB_APPLICATION_SOURCE, NOTICE_PERIOD_OPTIONS } from "../../constants";
import { agoTimestamp } from "../../utils/commonHelpers";
import { validateScreeningQuestions } from "../../utils/screeningQuestionHelpers";
import DangerousHtml from "../commons/DangerousHtml";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  inputFields: {
    marginTop: theme.spacing(1),
    // minWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  jobTitleHeader: {
    textAlign: "center",
  },
  shareOpeningContainer: {
    marginTop: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  verticalDivider: {
    height: "500px",
    marginRight: "-1px",
  },
  jobDescriptionContainer: {
    width: "100%",
    minHeight: "400px",
    textAlign: "left",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  resumeUploadField: {
    textAlign: "center",
    "& label": {
      marginBottom: "4px",
    },
  },
  inputLabel: {
    color: "#666666",
    marginBottom: 0,
    fontSize: "14px",
  },
  dividerContainer: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  rightContainer: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  mainGrid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "left",
    justifyContent: "left",
    padding: "0px 80px",
    fontSize: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
    },
  },
  notAcceptingApplicationsText: {
    color: "#d84315",
    fontSize: "16px",
    textAlign: "left",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "50px",
    },
  },
  postedOn: {
    fontSize: "14px",
    fontStyle: "italic",
    marginTop: "10px",
    color: "#666666",
  },
  dividerAllJobsButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "80%",
  },
  jobTypeContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    justifyContent: "center",
    fontSize: "12px",
  },
  jobTypeItems: {
    marginLeft: "4px",
  },
  termsAcceptLabel: {
    fontSize: "12px",
    width: "300px",
  },
  screeningQuestionContainer: {
    marginBottom: theme.spacing(0.5),
  },
  formCard: {
    padding: theme.spacing(4),
    boxSizing: "border-box",
    boxShadow: "none",
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    backgroundColor: "#F8F8F8",
    width: "100%",
    maxWidth: "1000px",
    margin: "auto",
  },
  applyContainer: {
    marginTop: theme.spacing(7),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
  submitForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  whiteDropArea: {
    background: "#fff",
  },
  descriptionGrid: {
    order: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  otherJobsGrid: {
    order: 2,
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      order: 3,
      marginTop: "50px",
    },
  },
  jobFormGrid: {
    order: 3,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  otherJobsDivider: {
    marginTop: "10px",
    marginBottom: "15px",
  },
  applicationFormDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  otherJobsLink: {
    textDecoration: "none",
    color: "black",
  },
  similarJobTitle: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  allJobsButton: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.99,
    },
  },
}));

const getThemeJson = (customStyle) => {
  return {
    palette: {
      primary: {
        main: customStyle.themePrimaryColor || "#08558b",
        contrastText: customStyle.themePrimaryTextColor || "#fff",
      },
      secondary: {
        main: customStyle.themeSecondaryColor || "#08558b",
        contrastText: customStyle.themeSecondaryTextColor || "#fff",
      },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: customStyle.themePrimaryTextColor || "#fff",
          "&:hover": {
            backgroundColor: customStyle.themePrimaryColor + "cc" || "#08558b",
          },
        },
        root: {
          fontWeight: "bolder",
        },
      },
      MuiInputBase: {
        input: {
          background: "#fff",
        },
        root: {
          background: "#fff",
        },
      },
    },
  };
};

const showNoticePeriodCTCFields = (companyDetails, requisition) => {
  if (
    companyDetails?.slug === "sapient" &&
    requisition &&
    requisition.locations &&
    requisition.locations.length > 0 &&
    requisition.locations[0].country === "United States"
  ) {
    return false;
  } else {
    return true;
  }
};

export default function ApplyPage(props) {
  const classes = useStyles();
  const params = getQueryParams();
  const { companySlug, requisitionSlug } = useParams();
  const [requisition, setRequisition] = React.useState(null);
  const [privacyPoliceRead, setPrivacyPoliceRead] = React.useState(false);
  const [uploadedFileContent, setUploadedFileContent] =
    React.useState("0 files selected");
  const [resumeFile, setResumeFile] = React.useState(null);
  const [isLoadingRequisitionDetails, setIsLoadingRequisitionDetails] =
    React.useState(true);
  const [errorValue, setErrorValue] = React.useState(null);
  const [submitting, setIsSubmitting] = React.useState(false);
  const [applicationSubmitted, setApplicationSubmitted] = React.useState(false);
  const [companyDetails, setCompanyDetails] = React.useState(null);
  const [otherJobs, setOtherJobs] = React.useState([]);
  const [themeJson, setThemeJson] = React.useState({});
  const [screeningQuestions, setScreeningQuestions] = React.useState([]);
  const [screeningQuestionAnswers, setScreeningQuestionAnswers] =
    React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const isNpCTCRequired =
    CTC_NP_REQUIRED_COMPANIES.indexOf(requisition?.company_id) > -1;

  const isCTCRequired =
    CTC_REQUIRED_COMPANIES.indexOf(requisition?.company_id) > -1;

  const [salaryCurrency, setSalaryCurrency] = React.useState(
    isIndia()
      ? {
          label: "INR",
          value: "INR",
        }
      : {
          label: "USD",
          value: "USD",
        }
  );
  const [noticePeriod, setNoticePeriod] = React.useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!e.target.elements.name.value) {
      enqueueSnackbar("Please enter name", reczeeAlertParams("error"));
      return;
    }

    if (!e.target.elements.email.value) {
      enqueueSnackbar("Please enter email", reczeeAlertParams("error"));
      return;
    }

    if (!e.target.elements.phone.value) {
      enqueueSnackbar("Please enter phone", reczeeAlertParams("error"));
      return;
    }

    if (!e.target.elements.experience.value) {
      enqueueSnackbar("Please enter experience", reczeeAlertParams("error"));
      return;
    }

    const currentCtcValue = e.target.elements.current_ctc?.value;
    const expectedCtcValue = e.target.elements.expected_ctc?.value;
    const chosenCurrency = salaryCurrency?.value;

    if (isNpCTCRequired) {
      if (
        !chosenCurrency ||
        !currentCtcValue ||
        !noticePeriod ||
        !expectedCtcValue
      ) {
        enqueueSnackbar(
          "Please fill all the required fields!",
          reczeeAlertParams("error")
        );
        return;
      }
    }

    if (isCTCRequired) {
      if (!chosenCurrency || !currentCtcValue || !expectedCtcValue) {
        enqueueSnackbar(
          "Please fill all the required fields!",
          reczeeAlertParams("error")
        );
        return;
      }
    }

    if ((currentCtcValue || expectedCtcValue) && !chosenCurrency) {
      enqueueSnackbar(
        "Please select a salary currency!",
        reczeeAlertParams("error")
      );
      return;
    }

    if (resumeFile === null) {
      enqueueSnackbar("Please upload resume", reczeeAlertParams("error"));
      return;
    }

    if (!privacyPoliceRead) {
      enqueueSnackbar("Please accept the terms!", reczeeAlertParams("error"));
      return;
    }

    let finalScreeningQuestionAnswers = { ...screeningQuestionAnswers };
    const allRequiredFilled = validateScreeningQuestions(
      screeningQuestions,
      finalScreeningQuestionAnswers
    );

    if (!allRequiredFilled) {
      enqueueSnackbar(
        "Please fill all the required fields",
        reczeeAlertParams("error")
      );
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData(e.target);

    formData.append("answers", JSON.stringify(finalScreeningQuestionAnswers));
    formData.append("candidate_resume", resumeFile);
    formData.append("company_id", requisition.company_id);
    formData.append("requisition_id", requisition.id);
    formData.append("requisition_slug", requisitionSlug);
    if (chosenCurrency) {
      formData.append("salary_currency", chosenCurrency);
    }
    if (noticePeriod) {
      formData.append("notice_period", noticePeriod);
    }
    if (params[JOB_APPLICATION_SOURCE]) {
      formData.append("source", params[JOB_APPLICATION_SOURCE]);
    }

    multiPartPostRequest(
      "candidate-application/apply",
      {},
      formData,
      () => {
        setUploadedFileContent("0 files selected");
        e.target.reset();
        setIsSubmitting(false);
        setApplicationSubmitted(true);
        enqueueSnackbar("Application submitted!", reczeeAlertParams("success"));
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  React.useEffect(() => {
    getRequest(
      `requisitions/from-slug/${requisitionSlug}`,
      { company_slug: companySlug },
      (data) => {
        setRequisition(data["requisition"]);
        setScreeningQuestions(data["screening_questions"]);
        setScreeningQuestionAnswers(
          data["screening_questions"].reduce((acc, question) => {
            acc[question.id] = null;
            return acc;
          }, {})
        );
        setCompanyDetails(data["company_details"]);
        setThemeJson(data["company_details"]["customStyle"]);
        setIsLoadingRequisitionDetails(false);
      },
      (errorMessages, statusCode) => {
        if (typeof errorMessages === "string") {
          setErrorValue(errorMessages);
        } else {
          setErrorValue(
            "Something went wrong! Please contact us if the issue persists."
          );
        }
        setIsLoadingRequisitionDetails(false);
      }
    );

    getRequest(
      "requisitions/get-other-jobs",
      { company_slug: companySlug, requisition_slug: requisitionSlug },
      (data) => {
        setOtherJobs(data["requisitions"]);
      },

      (errorMessages, statusCode) => {
        console.log(errorMessages);
        if (typeof errorMessages === "string") {
          setErrorValue(errorMessages);
        } else {
          setErrorValue(
            "Something went wrong! Please contact us if the issue persists."
          );
        }
        setIsLoadingRequisitionDetails(false);
      }
    );
  }, [companySlug, requisitionSlug, enqueueSnackbar]);

  function handleScreeningQuestionChange(id, value) {
    setScreeningQuestionAnswers((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  }

  const handleResumeUpload = async (files) => {
    if (files.length === 0) {
      setResumeFile(null);
      setUploadedFileContent("0 files selected");
      return;
    }
    if (files.length > 1) {
      enqueueSnackbar(
        `Please upload only a single file.`,
        reczeeAlertParams("error")
      );
      return;
    }
    let fileName = files[0].name.toLowerCase();
    let extension = fileName.split(".").slice(-1)[0];
    if (["pdf", "docx", "doc"].indexOf(extension) === -1) {
      enqueueSnackbar(
        "Invalid file type! Please upload pdf/doc files.",
        reczeeAlertParams("error")
      );
      return;
    }

    setResumeFile(files[0]);
    setUploadedFileContent(`${files[0].name} uploaded!`);
  };

  if (isLoadingRequisitionDetails) {
    return (
      <div style={{ marginTop: "40px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (errorValue) {
    return (
      <div className={classes.root} style={{ marginTop: "24px" }}>
        <Typography component="h3" variant="h5">
          {errorValue}
        </Typography>
      </div>
    );
  }

  document.title = `${requisition.designation} - ${companyDetails.name}`;

  return (
    <ThemeProvider
      theme={(baseTheme) => {
        return createMuiTheme(
          deepMergeJSON(baseTheme, getThemeJson(themeJson))
        );
      }}
    >
      <div className={classes.root}>
        <ReczeeJobsPageNavbar
          jobDetails={requisition}
          companyDetails={companyDetails}
          open_for_careers_page={requisition.open_for_careers_page}
        />
        <Grid container className={classes.mainGrid} gap={1}>
          <Grid item xs={12} sm={12} md={9} className={classes.descriptionGrid}>
            <DangerousHtml
              className={classes.jobDescriptionContainer}
              content={requisition.job_description}
            />
            <Typography variant="body2" className={classes.postedOn}>
              Posted {agoTimestamp(requisition.posted_on)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3} className={classes.otherJobsGrid}>
            {!requisition.open_for_careers_page ? (
              <div className={classes.notAcceptingApplicationsText}>
                Not accepting applications right now!
              </div>
            ) : (
              ""
            )}

            {otherJobs.length > 0 && (
              <>
                <Typography variant="subtitle2" style={{ fontSize: "16px" }}>
                  Other Jobs
                </Typography>
                {otherJobs.map((job) => (
                  <div key={job.id}>
                    <Divider className={classes.otherJobsDivider} />
                    <Link
                      to={`/${companySlug}/${job.slug}/apply`}
                      className={classes.otherJobsLink}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.similarJobTitle}
                      >
                        {job.designation}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: "12px" }}
                      >
                        {job.experience_range
                          ? `${job.experience_range[0]}-${job.experience_range[1]} yrs • `
                          : ""}
                        {job.locations && job.locations.length > 0
                          ? job.locations
                              .map((location) => location.city)
                              .join(", ")
                          : "-"}
                      </Typography>
                    </Link>
                  </div>
                ))}
                <Link
                  to={`/${companySlug}`}
                  className={classes.allJobsButton}
                  style={{ color: themeJson.themePrimaryColor }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                  >
                    View all jobs
                  </Typography>
                </Link>
              </>
            )}
          </Grid>
          <Grid item xs={12} className={classes.jobFormGrid}>
            <div className={classes.applyContainer}>
              {requisition.open_for_careers_page && !applicationSubmitted ? (
                <Card className={classes.formCard}>
                  <Typography
                    component="h3"
                    variant="h5"
                    style={{ fontWeight: "bold", fontSize: "24px" }}
                  >
                    Apply for this Job
                  </Typography>
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    className={classes.formContainer}
                    id="submit-application-form"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ marginTop: "16px" }}>
                        <ReczeeFileDropInputField
                          // title="Upload Resume"
                          required={true}
                          fileInputId="resume-upload"
                          buttonText="Upload Resume"
                          name="candidate_resume"
                          buttonSize="small"
                          onChange={handleResumeUpload}
                          uploadedFileContent={uploadedFileContent}
                          className={classes.whiteDropArea}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CareersPageInputField
                          title="Name"
                          name="name"
                          size="large"
                          placeholder="Enter your name"
                          className={classes.inputFields}
                          autoFocus={false}
                          required={true}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CareersPageInputField
                          title="Email"
                          name="email"
                          size="large"
                          placeholder="Enter your email"
                          className={classes.inputFields}
                          required={true}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CareersPageInputField
                          title="Phone"
                          name="phone"
                          size="large"
                          placeholder="Enter your phone number"
                          className={classes.inputFields}
                          required={true}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        style={{ alignItems: "left" }}
                      >
                        <CareersPageInputField
                          title="Experience (in years)"
                          name="experience"
                          size="large"
                          type="number"
                          inputProps={{ step: "0.1", min: "0" }}
                          placeholder="Enter your experience"
                          className={classes.inputFields}
                          autoComplete="off"
                          required={true}
                          fullWidth={true}
                        />
                      </Grid>
                      {showNoticePeriodCTCFields(
                        companyDetails,
                        requisition
                      ) ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <CareersPageCurrencyPicker
                                  title="Salary Currency"
                                  name="salary_currency"
                                  required={isNpCTCRequired || isCTCRequired}
                                  value={salaryCurrency}
                                  onChange={(newValue) =>
                                    setSalaryCurrency(newValue)
                                  }
                                  className={classes.inputFields}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <CareersPageInputField
                                  title="Current Salary"
                                  name="current_ctc"
                                  size="large"
                                  type="number"
                                  inputProps={{ step: "1", min: "0" }}
                                  placeholder="Enter current salary"
                                  className={classes.inputFields}
                                  autoComplete="off"
                                  required={isNpCTCRequired || isCTCRequired}
                                  fullWidth={true}
                                  helperText="Current annual salary in numbers"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <CareersPageInputField
                                  title="Expected Salary"
                                  name="expected_ctc"
                                  size="large"
                                  type="number"
                                  inputProps={{ step: "1", min: "0" }}
                                  placeholder="Enter expected salary"
                                  className={classes.inputFields}
                                  autoComplete="off"
                                  required={isNpCTCRequired || isCTCRequired}
                                  fullWidth={true}
                                  helperText="Expected annual salary in numbers"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <ReczeeAutoComplete
                                  single
                                  size="small"
                                  variant="outlined"
                                  title="Notice Period"
                                  required={isNpCTCRequired}
                                  className={classes.inputFields}
                                  inputClassName={classes.inputLabel}
                                  helperText={
                                    "How soon can you join? (in days)"
                                  }
                                  values={NOTICE_PERIOD_OPTIONS}
                                  getOptionLabel={(option) => option}
                                  getOptionSelected={(option, value) =>
                                    option === value
                                  }
                                  value={noticePeriod}
                                  onChange={(_, value) => {
                                    setNoticePeriod(value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    {screeningQuestions.length > 0 && (
                      <>
                        <Divider className={classes.applicationFormDivider} />
                        <Grid container spacing={2}>
                          {screeningQuestions.map((question) => (
                            <Grid
                              item
                              xs={12}
                              key={question.id}
                              className={classes.screeningQuestionContainer}
                            >
                              <CareersPageScreeningQuestionInput
                                key={question.id}
                                id={question.id}
                                title={question.title}
                                fieldType={question.field_type}
                                options={
                                  question.meta_data
                                    ? question.meta_data.options
                                    : null
                                }
                                required={question.required}
                                value={screeningQuestionAnswers[question.id]}
                                onChange={handleScreeningQuestionChange}
                                for="applyPage"
                              />
                            </Grid>
                          ))}
                        </Grid>
                        <Divider className={classes.applicationFormDivider} />
                      </>
                    )}
                    {companySlug === "options-india" ? (
                      <>
                        <CareersPageInputField
                          name="location"
                          size="large"
                          className={classes.inputFields}
                          label="Location"
                          autoComplete="off"
                        />
                        <CareersPageInputField
                          name="current_company"
                          size="large"
                          className={classes.inputFields}
                          label="Current Company"
                          autoComplete="off"
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <div className={classes.submitForm}>
                      <ReczeeCheckBox
                        checked={privacyPoliceRead}
                        onChange={(e) => {
                          setPrivacyPoliceRead(e.target.checked);
                        }}
                        name="privacy_policy"
                        label={
                          <span className={classes.termsAcceptLabel}>
                            I have read and accept the{" "}
                            <Link
                              variant="body2"
                              className={classes.termsAcceptLabel}
                              target="_blank"
                              href="https://www.reczee.com/privacy-policy"
                            >
                              Privacy Policy
                            </Link>{" "}
                            and{" "}
                            <Link
                              variant="body2"
                              target="_blank"
                              className={classes.termsAcceptLabel}
                              href="https://www.reczee.com/terms-and-conditions"
                            >
                              Terms and conditions
                            </Link>
                          </span>
                        }
                        className={classes.rememberMeField}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                        className={classes.submitButton}
                        size={"medium"}
                      >
                        {submitting ? "Applying..." : "Submit Application"}
                      </Button>
                    </div>
                  </form>
                </Card>
              ) : applicationSubmitted ? (
                "Your application is received ✅"
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
        <ReczeeJobsFooter companyDetails={companyDetails} />
      </div>
    </ThemeProvider>
  );
}
