import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import MicNoneIcon from "@material-ui/icons/MicNone";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import StopIcon from "@material-ui/icons/Stop";
import { postRequest } from "../../utils/httpHelpers";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import getAtsAgentHandler from "./atsAgentHelper";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import SpecificScheduleHandler from "./SpecificScheduleHandler";
import SendCandidateEmailHandler from "./SendCandidateEmailHandler";
import FilterCandidateSpreadsheetHandler from "./FilterCandidateSpreadsheetHandler";
import InviteUserHandler from "./InviteUserHandler";
import SuccessScreen from "./SuccessScreen";
import amplitudeLogger from "../../utils/amplitude";

const useStyles = makeStyles((theme) => ({
  suggestions: {
    borderRadius: "12px",
    padding: "4px 12px",
    fontSize: "12px",
    color: "#555555",
    textTransform: "none",
  },
  chipsContainer: {
    display: "flex",
    gap: "16px",
    marginTop: "16px",
    justifyContent: "space-evenly",
    width: "100%",
  },
  resultContainer: {
    width: "850px",
    background:
      "linear-gradient(90deg, rgba(62, 197, 255, 0.1) 0%, rgba(94, 121, 255, 0.1)  100%)",
    borderRadius: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    // marginBottom: "16px",
    padding: "20px",
    paddingTop: "26px",
    paddingBottom: "26px",
    marginTop: theme.spacing(3),
  },
  multilineOutline: {
    minHeight: "60px",
    padding: "2px 2px",
    border: "1px transparent",
    borderRadius: "16px",
    backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #0B61D9, #059DFF, #14B3F4, #2FA4E9, #E33FA1)`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    "&:hover": {
      borderColor: "unset",
    },
    "& fieldset": {
      border: "none", // Removes the border
    },
    "&:hover fieldset": {
      border: "none", // Removes border on hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Removes border when focused
    },
  },
  boxFooter: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
    paddingTop: "4px",
    paddingBottom: "4px",
    background:
      "linear-gradient(90deg, #0F5DBC 0%, #5099F8 50%, #14B3F4 85%, #e140a2 100%)",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
  },
  dialogPaper: {
    borderRadius: theme.spacing(2),
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(4, 3, 3, 4),
  },
  fadeInPlaceholder: {
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-root": {},
    "& input::placeholder": {
      animation: `$fadeinPlaceholder 2s ease-in-out`,
    },
  },
  notchedOutline: {
    borderColor: "red !important",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  boxContainer: {
    width: "850px",
    margin: "0 auto",
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  examplePromptContainer: {
    height: "35px",
    padding: "8px",
  },
  "@keyframes fadeinPlaceholder": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.5 },
  },
  submitButton: {
    padding: "5px",
    borderRadius: "50%",
    minWidth: "0px",
  },
  textContainer: {
    position: "relative",
    height: "75px",
    overflow: "hidden",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  textItem: {
    width: "100%",
    fontWeight: "400",
    opacity: 0,
    backgroundImage: "linear-gradient(to right, #0F5DBC, #072B56)",
    color: "transparent",
    backgroundClip: "text",
    transition:
      "transform 1s ease-out, opacity 1s ease-out, display 2s ease-out",
    padding: "0",
    margin: "0",
  },
  examplePromptText: {
    opacity: 1,
  },
  textItemCenter: {
    transform: "translateY(50%)",
    opacity: 1,
    fontWeight: "bold",
  },
  textItemBelow: {
    transform: "translateY(100%)",
    opacity: 0,
  },
  textItemAbove: {
    transform: "translateY(0%)",
    opacity: 0.5,
  },
}));

const placeholderValues = [
  "Schedule 30 min Tech round of Emily with Taylor on 23rd March 10 am",
  "Send screening rejection to Jack Mathews",
  "Show candidates who applied via LinkedIn last week",
  "Invite Michael (Director of Engineering) as Superuser",
  "Show candidates in hiring manager screening stage",
  "Invite agency Rehiro with email recruiter@rehiro.com",
  "What are the top 10 recruitment metrics I should monitor?",
  "Send self-scheduling link to John Doe with an Technical round with me",
  "Show candidates that are recruiter screen stage",
];

const loadingTexts = [
  {
    text: "Sending the prompt for processing...",
    time: 1,
  },
  {
    text: "Understanding the prompt...",
    time: 2,
  },
  {
    text: "Getting ready for the action...",
    time: 5,
  },
];

function replaceWords(inputStr) {
  let mapping = {
    rexy: "Reczee",
    rexi: "Reczee",
    recz: "Reczee",
    reczy: "Reczee",
    "by a": "via",
  };
  // Iterate over the keys in the mapping (false words)
  for (let thisWord in mapping) {
    thisWord = thisWord.toLowerCase();
    if (mapping.hasOwnProperty(thisWord)) {
      // Create a regular expression to match the whole word
      const regex = new RegExp(`\\b${thisWord}\\b`, "gi"); // 'g' for global, 'i' for case-insensitive
      // Replace occurrences of the false word with the correct word
      inputStr = inputStr.replace(regex, mapping[thisWord]);
    }
  }
  return inputStr;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function AtsAgentModal({
  atsAgentModalOpen,
  setAtsAgentModalOpen,
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] =
    React.useState(0);
  const [isListening, setIsListening] = React.useState(false);
  const [recognition, setRecognition] = React.useState(null);
  const [parsedPrompt, setParsedPrompt] = React.useState({});

  const [examplePrompt, setExamplePrompt] = React.useState("");
  const [animationStart, setAnimationStart] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const textContainerRef = React.useRef(null);
  const [currentLoadingTextIndex, setCurrentLoadingTextIndex] =
    React.useState(1);
  const [successData, setSuccessData] = React.useState(null);

  const silenceTimeout = 1000; // 1 seconds of silence before stopping
  let silenceTimerId = null;
  let atsAgentHandler = null;

  const resetAction = (action, from) => {
    amplitudeLogger.logEvent("ATS_AGENT_RESET_ACTION", {
      from: from,
      action: action,
    });
    setInputValue("");
    setParsedPrompt({});
    setErrorMessage(null);
    setExamplePrompt("");
    setSuccessData(null);
  };

  const onActionComplete = (data) => {
    setSuccessData(data);
  };

  if (Object.keys(parsedPrompt).length > 0) {
    atsAgentHandler = new (getAtsAgentHandler(parsedPrompt.action))(
      parsedPrompt.action,
      parsedPrompt,
      resetAction,
      onActionComplete
    );
  } else {
    atsAgentHandler = null;
  }

  React.useEffect(() => {
    // Initialize SpeechRecognition instance
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const newRecognition = new SpeechRecognition();
    newRecognition.lang = "en-In";
    newRecognition.interimResults = true;
    // newRecognition.continuous = true;

    newRecognition.onresult = (event) => {
      const currentTranscript = Array.from(event.results)
        .map((result) => replaceWords(result[0].transcript))
        .join(" ");
      setInputValue(currentTranscript);
      resetSilenceTimer(); // Reset the silence timer on speech detection
    };

    newRecognition.onend = () => {
      // setIsListening(false);
      console.log("Speech recognition stopped due to silence.");
    };

    setRecognition(newRecognition);
  }, []);

  const startListening = React.useCallback(() => {
    amplitudeLogger.logEvent("ATS_AGENT_MIC_CLICK", {
      kind: "start",
    });
    if (recognition) {
      recognition.start();
      setIsListening(true);
      // resetSilenceTimer(); // Start the silence timer
    }
  }, [recognition]);

  const stopListening = React.useCallback(() => {
    amplitudeLogger.logEvent("ATS_AGENT_MIC_CLICK", {
      kind: "stop",
    });
    if (recognition) {
      recognition.stop();
    }
    if (silenceTimerId) clearTimeout(silenceTimerId);
    setIsListening(false);
  }, [recognition]);

  const resetSilenceTimer = React.useCallback(() => {
    clearTimeout(silenceTimerId);
    silenceTimerId = setTimeout(() => {
      stopListening();
    }, silenceTimeout);
  }, [recognition]);

  React.useEffect(() => {
    const checkParsedPrompt = setInterval(() => {
      if (
        currentLoadingTextIndex >= loadingTexts.length &&
        Object.keys(parsedPrompt).length > 0
      ) {
        setAnimationStart(false);
        clearInterval(checkParsedPrompt); // Clear the interval when parsedPrompt is available
      }
    }, 500); // Check every 500ms
    return () => {
      clearInterval(checkParsedPrompt);
    };
  }, [currentLoadingTextIndex, parsedPrompt]);

  React.useEffect(() => {
    if (animationStart) {
      animateText();
    }
  }, [animationStart, currentLoadingTextIndex]);

  const onChipSelect = (action) => {
    amplitudeLogger.logEvent("ATS_AGENT_PRE_FILL_CHIP", {
      action: action,
    });
  };

  const animateText = React.useCallback(async () => {
    if (!loadingTexts || loadingTexts.length === 0) return;
    if (currentLoadingTextIndex >= loadingTexts.length) return;

    const previousIndex = currentLoadingTextIndex - 1;
    const nextIndex = currentLoadingTextIndex + 1;

    await sleep(loadingTexts[previousIndex]?.time * 1000 || 2000);

    const textItems = textContainerRef.current?.querySelectorAll(
      `.${classes.textItem}`
    );

    if (textItems) {
      textItems.forEach((item, outerIndex) => {
        item.classList.remove(
          classes.textItemCenter,
          classes.textItemAbove,
          classes.textItemBelow
        );

        if (outerIndex === currentLoadingTextIndex) {
          item.classList.add(classes.textItemCenter);
        } else if (outerIndex === nextIndex) {
          item.classList.add(classes.textItemBelow);
        } else if (outerIndex === previousIndex) {
          item.classList.add(classes.textItemAbove);
        }
      });
    }

    if (currentLoadingTextIndex <= loadingTexts.length) {
      setCurrentLoadingTextIndex((prev) => prev + 1);
    }
  }, [currentLoadingTextIndex, parsedPrompt]);

  React.useEffect(() => {
    if (animationStart) {
      animateText();
    }
  }, [currentLoadingTextIndex]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    amplitudeLogger.logEvent("ATS_AGENT_INPUT_SUBMIT", {
      input_length: inputValue.length,
      from: "enter_key",
    });

    if (inputValue.length === 0) {
      enqueueSnackbar("Please enter a prompt", reczeeAlertParams("error"));
      return;
    }

    if (inputValue.length > 300) {
      enqueueSnackbar(
        "Please write a shorter prompt (Upto 300 characters)",
        reczeeAlertParams("error")
      );
      return;
    }

    setCurrentLoadingTextIndex(1);
    setErrorMessage(null);
    setIsSubmitting(true);
    setAnimationStart(true);
    setParsedPrompt({});

    postRequest(
      "ats-agent/parse-prompt",
      {},
      {
        prompt: inputValue,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (data) => {
        setParsedPrompt({ ...data, prompt: inputValue });
        setIsSubmitting(false);
        amplitudeLogger.logEvent("ATS_AGENT_RESPONSE_SUCCESS", {
          action: data.action,
        });
        // setAnimationStart(false); // we removing this because we are setting it false in updateText()
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        setAnimationStart(false);

        if (typeof errorMessages === "string") {
          amplitudeLogger.logEvent("ATS_AGENT_RESPONSE_ERROR", {
            error: errorMessages,
          });
          setErrorMessage(errorMessages);
        } else {
          amplitudeLogger.logEvent("ATS_AGENT_RESPONSE_ERROR", {
            error: "Something went wrong",
          });
          setErrorMessage("Something went wrong");
        }
      }
    );
  };

  React.useEffect(() => {
    if (!inputValue) {
      setExamplePrompt("");
    }
  }, [inputValue]);

  React.useEffect(() => {
    let intervalValue = setInterval(() => {
      setCurrentPlaceholderIndex((prev) => prev + 1);
    }, 4000);

    return () => {
      clearInterval(intervalValue);
    };
  }, []);

  const onModalClose = () => {
    setAtsAgentModalOpen(false);
    amplitudeLogger.logEvent("ATS_AGENT_MODAL", {
      state: "close",
    });
  };

  return (
    <Dialog
      maxWidth="md"
      disableBackdropClick={true}
      open={atsAgentModalOpen}
      className={classes.dialogRoot}
      disableEscapeKeyDown={false}
      onClose={onModalClose}
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth={true}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust blur
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity
        },
      }}
    >
      <MuiDialogTitle disableTypography>
        <div className={classes.dialogTitle}>
          {!successData &&
            (atsAgentHandler ? (
              atsAgentHandler.getModalHeader()
            ) : (
              <Typography variant="h5" component="h5">
                What would you like to do today?
              </Typography>
            ))}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onModalClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </MuiDialogTitle>

      {!successData ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap="16px"
            p={2}
            className={classes.boxContainer}
          >
            <TextField
              fullWidth
              multiline
              rowsMax={5}
              variant="outlined"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              autoFocus
              className={classes.fadeInPlaceholder}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault(); // Prevent the default form submission
                  handleSubmit();
                }
              }}
              placeholder={
                placeholderValues[
                  currentPlaceholderIndex % placeholderValues.length
                ]
              }
              InputProps={{
                classes: {
                  root: classes.multilineOutline,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={getStaticResourceUrl("/ats-agent-stars.svg")}
                      width="24"
                      height="24"
                      alt="star"
                      style={{ marginLeft: "16px", userSelect: "none" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {inputValue.length > 0 && (
                      <Button
                        onClick={() => {
                          setInputValue("");
                          setExamplePrompt("");
                        }}
                        style={{ minWidth: "0px", padding: "0px" }}
                      >
                        <CloseIcon />
                      </Button>
                    )}
                    <IconButton
                      style={{ marginRight: "4px" }}
                      onClick={() => {
                        if (isListening) {
                          stopListening();
                        } else {
                          startListening();
                        }
                      }}
                      disabled={isSubmitting}
                    >
                      {isListening ? (
                        <StopIcon style={{ color: "red" }} />
                      ) : (
                        <MicNoneIcon
                          fontSize="medium"
                          style={{ color: "black" }}
                        />
                      )}
                    </IconButton>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.submitButton}
                      disabled={isSubmitting}
                      style={{ marginRight: "16px" }}
                    >
                      <ArrowUpwardIcon
                        fontSize="small"
                        style={{ padding: "0px" }}
                      />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            {(!atsAgentHandler || animationStart) && (
              <Box className={classes.chipsContainer}>
                {animationStart ? (
                  <p ref={textContainerRef} className={classes.textContainer}>
                    {loadingTexts.map((text, index) => {
                      return (
                        <span
                          className={`${classes.textItem} ${
                            index === 0 ? classes.textItemCenter : ""
                          }
                    ${index === 1 ? classes.textItemBelow : ""}`}
                        >
                          {text.text}
                        </span>
                      );
                    })}
                  </p>
                ) : examplePrompt.length === 0 ? (
                  <>
                    {SpecificScheduleHandler.renderChip(
                      setInputValue,
                      setExamplePrompt,
                      onChipSelect
                    )}
                    {SendCandidateEmailHandler.renderChip(
                      setInputValue,
                      setExamplePrompt,
                      onChipSelect
                    )}
                    {FilterCandidateSpreadsheetHandler.renderChip(
                      setInputValue,
                      setExamplePrompt,
                      onChipSelect
                    )}
                    {InviteUserHandler.renderChip(
                      setInputValue,
                      setExamplePrompt,
                      onChipSelect
                    )}
                  </>
                ) : (
                  <div className={classes.examplePromptContainer}>
                    Example prompt:{" "}
                    <span
                      className={`${classes.textItem} ${classes.examplePromptText}`}
                    >
                      {examplePrompt}
                    </span>
                  </div>
                )}
              </Box>
            )}

            {errorMessage && (
              <Box className={classes.chipsContainer}>
                <span style={{ color: "red" }}>{errorMessage}</span>
              </Box>
            )}
          </Box>
        </>
      ) : atsAgentHandler ? (
        <div className={classes.resultContainer}>
          <SuccessScreen successData={successData} />
        </div>
      ) : (
        ""
      )}
      {!successData && !animationStart && atsAgentHandler && !errorMessage ? (
        <div className={classes.resultContainer}>
          {atsAgentHandler.renderBody()}
        </div>
      ) : (
        ""
      )}
      <div className={classes.boxFooter}>
        <img
          src={getStaticResourceUrl("/white-ats-agent-stars.svg")}
          width="14"
          height="14"
          alt="star"
          style={{ marginRight: "4px" }}
        />
        Powered by AI
      </div>
    </Dialog>
  );
}
