import React from "react";
import { Redirect } from "react-router";
import {
  isLoggedIn,
  isTalentHubCandidateLoggedIn,
} from "../../utils/authHelpers";

export default function MainPage() {
  if (isLoggedIn()) {
    if (isTalentHubCandidateLoggedIn()) {
      return <Redirect push to="/talenthub/dashboard"></Redirect>;
    } else {
      return <Redirect push to="/dashboard"></Redirect>;
    }
  } else {
    return <Redirect push to="/login"></Redirect>;
  }
}
