import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import { getRequest } from "../../utils/httpHelpers";
import { getOauthLocalStorageKey } from "../../utils/authHelpers";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";

const useStyles = makeStyles((theme) => ({
  connectGoogleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectGoogleButton: {
    cursor: "pointer",
    height: "36px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  connectOutlookButton: {
    cursor: "pointer",
    height: "36px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  connectedGoogle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectedOutlook: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tickContainter: {
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "green",
  },
}));

const messageMap = {
  success: "Successfully integrated!",
  email_mismatch: "Please authorize with registered email!",
  access_denied: "Please accept all the scopes!",
  consent_required: "Please accept all the scopes!",
  jwt_error: "Something went wrong!",
  scope_not_accepted: "Please accept all the scopes!",
  refresh_token_not_present:
    "Please revoke access and try again - myaccount.google.com/permissions",
};

export default function CalendarIntegration({ onProviderConnected }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [oauthRedirectInfo, setOauthRedirectInfo] = React.useState({});

  const getCalendarProvider = () => {
    if (!oauthRedirectInfo) return null;
    if (oauthRedirectInfo.state === "google_connected") return "google";
    if (oauthRedirectInfo.state === "outlook_connected") return "outlook";
    return null;
  };

  const calendarProvider = getCalendarProvider();

  React.useEffect(() => {
    getRequest(
      `oauth/generate-redirect-url`,
      {
        redirect_url: window.location.href,
        kind: "calendar",
      },
      (data) => {
        setOauthRedirectInfo(data);
        if (
          data &&
          (data.state === "google_connected" ||
            data.state === "outlook_connected")
        ) {
          onProviderConnected &&
            onProviderConnected(
              data.state === "google_connected" ? "google" : "outlook"
            );
        }
      },
      (errorMessages, statusCode) => {}
    );
    // eslint-disable-next-line
  }, []);

  const connectGoogleHandler = () => {
    if (oauthRedirectInfo && oauthRedirectInfo.state !== "google_connected") {
      const tab = window.open(oauthRedirectInfo.google_url, "_blank");
      let setIntervalId = setInterval(() => {
        let oauthStatus = localStorage.getItem(
          getOauthLocalStorageKey(oauthRedirectInfo.transaction_id)
        );
        if (oauthStatus === null) {
          return;
        }
        clearInterval(setIntervalId);
        localStorage.removeItem(
          getOauthLocalStorageKey(oauthRedirectInfo.transaction_id)
        );

        if (tab) {
          tab.close();
        }
        if (oauthStatus === "success") {
          enqueueSnackbar(
            messageMap[oauthStatus],
            reczeeAlertParams("success")
          );
          setOauthRedirectInfo({
            ...oauthRedirectInfo,
            state: "google_connected",
          });
          onProviderConnected && onProviderConnected("google");
        } else {
          enqueueSnackbar(messageMap[oauthStatus], reczeeAlertParams("error"));
          setOauthRedirectInfo({
            ...oauthRedirectInfo,
            state: "google_errored",
          });
        }
      }, 1000);
    }
  };

  const connectOutlookHandler = () => {
    if (oauthRedirectInfo && oauthRedirectInfo.state !== "outlook_connected") {
      const tab = window.open(oauthRedirectInfo.outlook_url, "_blank");
      let setIntervalId = setInterval(() => {
        let oauthStatus = localStorage.getItem(
          getOauthLocalStorageKey(oauthRedirectInfo.transaction_id)
        );
        if (oauthStatus === null) {
          return;
        }
        clearInterval(setIntervalId);
        localStorage.removeItem(
          getOauthLocalStorageKey(oauthRedirectInfo.transaction_id)
        );
        if (tab) {
          tab.close();
        }
        if (oauthStatus === "success") {
          enqueueSnackbar(
            messageMap[oauthStatus],
            reczeeAlertParams("success")
          );
          setOauthRedirectInfo({
            ...oauthRedirectInfo,
            state: "outlook_connected",
          });
          onProviderConnected && onProviderConnected("outlook");
        } else {
          enqueueSnackbar(messageMap[oauthStatus], reczeeAlertParams("error"));
          setOauthRedirectInfo({
            ...oauthRedirectInfo,
            state: "outlook_errored",
          });
        }
      }, 1000);
    }
  };

  return (
    <Grid item>
      {calendarProvider ? (
        calendarProvider === "google" ? (
          <Typography className={classes.connectedGoogle}>
            Google connected{" "}
            <span className={classes.tickContainter}>
              <CheckCircleIcon />
            </span>
          </Typography>
        ) : (
          <Typography className={classes.connectedOutlook}>
            Outlook connected{" "}
            <span className={classes.tickContainter}>
              <CheckCircleIcon />
            </span>
          </Typography>
        )
      ) : (
        <div className={classes.connectGoogleContainer}>
          <b>Connect calendar:</b>
          <img
            className={classes.connectGoogleButton}
            onClick={connectGoogleHandler}
            alt="Connect Google"
            src={getStaticResourceUrl("/connect-google.svg")}
          />
          {oauthRedirectInfo && oauthRedirectInfo.outlook_url ? (
            <img
              className={classes.connectOutlookButton}
              onClick={connectOutlookHandler}
              alt="Connect Outlook"
              src={getStaticResourceUrl("/connect-microsoft.svg")}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </Grid>
  );
}
