import { Chip, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { requisitionStateBadgeColors } from "../../constants.js";

const getBackgroundColor = (props) => {
  return requisitionStateBadgeColors[props.state]?.backgroundColor || "#000";
};

const getTextColor = (props) => {
  return requisitionStateBadgeColors[props.state]?.color || "#fff";
};

const useStyles = makeStyles((theme) => ({
  stateBadge: {
    minWidth: "50px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "bold",
    backgroundColor: getBackgroundColor,
    color: getTextColor,
    height: (props) => (props.small ? "unset" : ""),
    "& .MuiChip-deleteIcon": {
      color: "#ffffff94",
    },
  },
  dotColor: ({ state }) => {
    let color = requisitionStateBadgeColors[state]?.backgroundColor || "grey";
    return {
      color: color,
      cursor: "pointer",
    };
  },
  requisitionTitleWithState: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}));

export function formatStateName(state) {
  if (!state) return "Unknown";
  return state
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function RequisitionStateDot({ state }) {
  let classes = useStyles({ state: state });
  return (
    <Tooltip title={formatStateName(state)} placement="right">
      <span className={classes.dotColor}>●</span>
    </Tooltip>
  );
}

export function RequisitionTitleWithState({ title, state }) {
  let classes = useStyles({ state: state });
  return (
    <div className={classes.requisitionTitleWithState}>
      {title}
      {state ? <RequisitionStateDot state={state} /> : ""}
    </div>
  );
}

export function RequisitionStateBadge(props) {
  const classes = useStyles(props);

  let stateContent = (
    <Chip
      size="small"
      label={props.label}
      onDelete={props.onDelete}
      className={`${classes.stateBadge} ${props.className}`}
    />
  );
  if (props.hoverMessage) {
    stateContent = (
      <Tooltip arrow title={props.hoverMessage}>
        {stateContent}
      </Tooltip>
    );
  }
  return stateContent;
}
