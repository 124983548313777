import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typography: {
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
}));

export default function AtsAgentModalHeader(props) {
  const classes = useStyles();
  return (
    <Typography variant="h5" component="h5" className={classes.typography}>
      <div className={classes.iconContainer}>{props.icon}</div>
      {props.title}
    </Typography>
  );
}
