import React from "react";

class BaseAgentHandler {
  constructor(action, parsedPrompt, resetAction, onActionComplete) {
    this.action = action;
    this.parsedPrompt = parsedPrompt;
    this.resetAction = resetAction;
    this.onActionComplete = onActionComplete;
  }

  renderBody() {
    return <div></div>;
  }
}

export default BaseAgentHandler;
