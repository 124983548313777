import confetti from "canvas-confetti";
import Forbidden from "../components/commons/errors/Forbidden";
import InternalServerError from "../components/commons/errors/InternalServerError";
import PageNotFound from "../components/commons/errors/PageNotFound";
import { alertConfigParams } from "../constants";

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function isIndia() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta";
}

export function justDateYYYYMMDD(timeObj) {
  if (!timeObj) {
    return null;
  }
  let year = String(timeObj.getFullYear());
  let month = String(timeObj.getMonth() + 1).padStart(2, "0");
  let day = String(timeObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function dateTime() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const time = today.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const dateTimeString = `${year}-${month}-${day} ${time}`;

  return dateTimeString;
}

export function deepMergeJSON(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMergeJSON(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMergeJSON(target, ...sources);
}

export const reczeeAlertParams = (variant) => {
  return {
    ...{ variant: variant },
    ...alertConfigParams,
  };
};

export const isJobsDomain = () => {
  return (
    window.location.host === "jobs-dev.reczee.com" ||
    window.location.host === "jobs.reczee.com" ||
    window.location.host === "jobs-stag.reczee.com"
  );
};

export const isAuthRoute = () => {
  return window.location.pathname === "/login";
};

export const capitalizeWord = (word) => {
  return (word.charAt(0).toUpperCase() + word.slice(1)).replace(/_/g, " ");
};

export const formatTimestamp = (timestamp) => {
  // @Todo(Raj): Make this to something nicer
  const timestampObj = new Date(timestamp);
  return (
    timestampObj.toLocaleDateString() + " " + timestampObj.toLocaleTimeString()
  );
};

export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export function getShortMonthLabel(dateTimestamp) {
  if (!dateTimestamp) return "";
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthNames[dateTimestamp.getMonth()];
}

export function getMonthLabel(monthValue) {
  if (!monthValue) return "";
  if (monthValue === "Present") return monthValue;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let dateTimestamp = new Date(monthValue);
  return (
    monthNames[dateTimestamp.getMonth()] + " " + dateTimestamp.getFullYear()
  );
}

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const padZero = (num) => {
  if (num < 10) return "0" + num.toString();
  return num.toString();
};

export const reczeeConfettiAnimate = (onCompleteCallback) => {
  function fire(particleCount, x, y) {
    confetti(
      Object.assign(
        {},
        defaults,
        {},
        {
          colors: ["#093d91", "#099120", "#a8f0b4", "#8b948d", "#1948b5"],
          particleCount: particleCount,
          origin: {
            x: x,
            y: y,
          },
        }
      )
    );
  }

  var duration = 2 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 20, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  var interval = setInterval(function () {
    var timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      clearInterval(interval);
      onCompleteCallback();
      return;
    }

    var particleCount = 70 * (timeLeft / duration);
    fire(particleCount, randomInRange(0, 0.25), randomInRange(0, 0.6));
    fire(particleCount, randomInRange(0.25, 0.5), randomInRange(0, 0.6));
    fire(particleCount, randomInRange(0.5, 0.75), randomInRange(0, 0.6));
    fire(particleCount, randomInRange(0.75, 1.0), randomInRange(0, 0.6));
  }, 250);
};

export const basicPluralize = (
  count,
  noun,
  suffix = "s",
  justSuffix = false
) => {
  if (justSuffix) {
    return `${noun}${count !== 1 ? suffix : ""}`;
  } else {
    return `${count} ${noun}${count !== 1 ? suffix : ""}`;
  }
};

export const timestampDifference = (elapsed, agoOrToGo, decimalPlaces) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  const pluralize = (value, timeString) => {
    return `${value} ${value > 1 ? timeString + "s" : timeString} ${agoOrToGo}`;
  };

  if (elapsed < msPerMinute) {
    return pluralize(
      parseFloat(elapsed / 1000).toFixed(decimalPlaces),
      "second"
    );
  } else if (elapsed < msPerHour) {
    return pluralize(
      parseFloat(elapsed / msPerMinute).toFixed(decimalPlaces),
      "minute"
    );
  } else if (elapsed < msPerDay) {
    return pluralize(
      parseFloat(elapsed / msPerHour).toFixed(decimalPlaces),
      "hour"
    );
  } else if (elapsed < msPerMonth) {
    return pluralize(
      parseFloat(elapsed / msPerDay).toFixed(decimalPlaces),
      "day"
    );
  } else if (elapsed < msPerYear) {
    return pluralize(
      parseFloat(elapsed / msPerMonth).toFixed(decimalPlaces),
      "month"
    );
  } else {
    return null;
  }
};

export const agoTimestamp = (timestamp) => {
  timestamp = new Date(timestamp);
  var elapsed = new Date() - timestamp;
  var result = timestampDifference(elapsed, "ago", 0);
  if (!result) {
    return formatTimestamp(timestamp);
  } else {
    return result;
  }
};

export const agoDate = (timestamp) => {
  timestamp = new Date(timestamp);
  var elapsed = new Date() - timestamp;
  var result = timestampDifference(elapsed, "ago", 0);
  if (!result) {
    return timestamp.toLocaleDateString();
  } else {
    return result;
  }
};

export const justDateTimestamp = (timestamp) => {
  return new Date(timestamp).toDateString();
};

export const shortTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString("default", {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
  });
};

export const getFormattedGradLabel = (
  graduation_year,
  post_graduation_year
) => {
  let result = "";
  if (graduation_year) {
    result += "Grad: " + graduation_year;
  }
  if (post_graduation_year) {
    if (graduation_year) {
      result += ", ";
    }
    result += "Postgrad: " + post_graduation_year;
  }
  return result;
};

export const justTimeTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString("default", {
    hour: "numeric",
    minute: "numeric",
  });
};

export const shortWithYearTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString("default", {
    year: "2-digit",
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
  });
};

export const formatTime = (timestamp) => {
  // @Todo(Raj): Make this to something nicer
  const timestampObj = new Date(timestamp);
  return timestampObj.toLocaleTimeString().split(":").splice(0, 2).join(":");
};

export const getErrorContent = (errorCode) => {
  if (errorCode === 404) {
    return <PageNotFound />;
  }

  if (errorCode === 403) {
    return <Forbidden />;
  }

  if (errorCode >= 500) {
    return <InternalServerError />;
  }

  return null;
};

export const formatToHHMM = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};
