import {
  Button,
  CssBaseline,
  Link as CoreLink,
  MenuItem,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import { ReczeeInputField } from "./ReczeeInputField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { RequisitionStateDot } from "../requisitions/RequisitionStateBadge";
import MoreIcon from "@material-ui/icons/MoreVert";
import { postRequest } from "../../utils/httpHelpers";
import SearchIcon from "@material-ui/icons/Search";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import { NotificationContext } from "../../contexts/NotificationContext";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import amplitudeLogger from "../../utils/amplitude";
import {
  isAgencyUser,
  isCompanyPrivilegedUser,
  isLoggedIn,
  isHiringManager,
  isTalentHubCandidateLoggedIn,
} from "../../utils/authHelpers";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { getNavbarList } from "../../utils/navbarConstants";
import { getAbsoluteUrl, getStaticResourceUrl } from "../../utils/urlHelpers";
import SearchBar from "./SearchBar";
import { ReczeeDialog } from "./ReczeeDialog";
import AtsAgentModal from "../ats_agent/AtsAgentModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  fullWidthContainer: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    display: "block",
    marginLeft: "auto",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 4px 20px 5px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#FFF",
    },
  },
  corelinkColor: {
    textDecoration: "none",
    color: "#000000de",
    "&:hover": {
      textDecoration: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  toolbarContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  sectionDesktop: {
    width: "100%",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  withProductHuntContainer: {
    margin: "auto",
    display: "flex",
  },
  aiAgentButtonContainer: {
    // width: 200, // Box width
    // height: 200, // Box height
    position: "relative",
    borderRadius: "50%", // Rounded corners
    overflow: "hidden", // Ensures child content stays within bounds
    backgroundColor: "white", // Inner background
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    scale: 0.9,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "50%", // Same as Box
      padding: "2px", // Border thickness
      background:
        "linear-gradient(90deg, #0B61D9 0%, #059DFF 31%, #14B3F4 66%, #2FA4E9 79.5%, #E33FA1 100%)", // Gradient border
      webkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      webkitMaskComposite: "destination-out",
      maskComposite: "exclude",
      zIndex: 1,
    },
  },
  rightSearchContainer: {
    marginLeft: "auto",
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
  },
  radioGroupFormControl: {
    marginTop: theme.spacing(1),
    color: "inherit",
    width: "100%",
    justifyContent: "space-evenly",
    "& .Mui-focused": {
      color: "inherit",
    },
    "& .MuiFormLabel-root": {
      color: "inherit",
    },
    "& .MuiFormGroup-root": {
      justifyContent: "space-evenly",
    },
  },
  sectionMobile: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function ReczeeNavbar(props) {
  const classes = useStyles();
  const isTalentHub = window.location.pathname.startsWith("/talenthub");
  const isScreeno = window.location.pathname.startsWith("/screeno");
  const isTalentHubJobsPage = window.location.pathname === "/talenthub/jobs";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const menuId = "primary-search-account-menu";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  const [atsAgentModalOpen, setAtsAgentModalOpen] = React.useState(false);
  const [openScreenoFeedbackModal, setOpenScreenoFeedbackModal] =
    React.useState(false);
  const [notificationInfo, setNotificationInfo] =
    React.useContext(NotificationContext);

  const agencyUser = isAgencyUser(userInfo.roles);
  const hiringManager = isHiringManager(userInfo.roles);
  const companyPrivileged = isCompanyPrivilegedUser(userInfo.roles);
  const atsAgentAllowed = hiringManager || companyPrivileged;

  const navbarItemClick = (value) => () => {
    amplitudeLogger.logEvent("NAVBAR_ITEM_CLICK", { value: value });
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        atsAgentAllowed &&
        (event.ctrlKey || event.metaKey) &&
        event.key === "k"
      ) {
        amplitudeLogger.logEvent("ATS_AGENT_MODAL", {
          from: "keyboard_shortcut",
          state: "open",
        });
        event.preventDefault(); // Prevent default browser action
        setAtsAgentModalOpen(true);
      }
    };

    const img = new Image();
    img.src = "/react-static/ats-agent-stars.svg";

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleScreenoFeedbackSubmit = (e) => {
    e.preventDefault();

    if (!e.target.elements.score.value) {
      enqueueSnackbar(
        "Please select a score value",
        reczeeAlertParams("error")
      );
      return;
    }

    postRequest(
      "/submit-product-feedback",
      {},
      {
        product_name: "Screeno",
        description: e.target.elements.description.value,
        score: e.target.elements.score.value,
      },
      () => {
        e.target.reset();
        setOpenScreenoFeedbackModal(false);
        enqueueSnackbar(
          "Thank you for your valuable feedback!",
          reczeeAlertParams("success")
        );
      },
      (errorMessages) => {
        setOpenScreenoFeedbackModal(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (itemLabel) => (event) => {
    if (event && event.target.innerText) {
      amplitudeLogger.logEvent("PROFILE_MENU_CLICK", {
        value: itemLabel || event.target.innerText,
      });
    }
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (amplitudeParams) => () => {
    if (amplitudeParams) {
      amplitudeLogger.logEvent(
        amplitudeParams.eventName,
        amplitudeParams.eventProperties
      );
    }
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const handleLogout = () => {
    amplitudeLogger.logEvent(isTalentHub ? "TALENTHUB_LOGOUT" : "LOGOUT", {
      reason: "user_action",
    });
    amplitudeLogger.endSession();
    setUserInfo({});
    setNotificationInfo(null);
    enqueueSnackbar("Successfully logged out!", reczeeAlertParams("success"));
    handleMenuClose()();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navbarList = getNavbarList("navbar", {
    ...userInfo,
    ...notificationInfo,
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ClickAwayListener onClickAway={() => {}}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <div className={classes.sectionDesktop}>
              {isTalentHub ? (
                <div className={classes.toolbarContent}>
                  <div />
                  <div className={classes.withProductHuntContainer}>
                    <img
                      height="40"
                      alt="Reczee TalentHub"
                      style={{ marginLeft: "8px", marginRight: "8px" }}
                      src={getStaticResourceUrl(
                        "/reczee-dark-talenthub-logo.svg"
                      )}
                    />
                  </div>
                  <div style={{ marginLeft: "auto", alignSelf: "center" }}>
                    {!isLoggedIn() ? (
                      <Button
                        component={CoreLink}
                        href={getAbsoluteUrl("app", "/talenthub/register")}
                        startIcon={
                          <AccountCircleOutlinedIcon color="primary" />
                        }
                        onClick={navbarItemClick("CREATE MY PROFILE")}
                        className={classes.corelinkColor}
                        disableRipple
                      >
                        <b>Create my profile</b>
                      </Button>
                    ) : (
                      ""
                    )}
                    {isTalentHubJobsPage && !isTalentHubCandidateLoggedIn() ? (
                      <Button
                        onClick={navbarItemClick("POST JOB")}
                        startIcon={<BusinessCenterIcon color="primary" />}
                        component={CoreLink}
                        href={getAbsoluteUrl("app", "/talenthub/post-job")}
                        disableRipple
                        className={classes.corelinkColor}
                      >
                        <Badge variant="dot" color="secondary">
                          <b>Post job</b>
                        </Badge>
                      </Button>
                    ) : (
                      ""
                    )}
                    {!isTalentHubJobsPage && !isLoggedIn() ? (
                      <Button
                        component={CoreLink}
                        startIcon={<SearchIcon color="primary" />}
                        href={getAbsoluteUrl("app", "/talenthub/jobs")}
                        onClick={navbarItemClick("FIND JOBS")}
                        disableRipple
                        className={classes.corelinkColor}
                      >
                        <Badge variant="dot" color="secondary">
                          <b>Find jobs</b>
                        </Badge>
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className={classes.toolbarContent}>
                  <div />
                  {isScreeno && isLoggedIn() ? (
                    <>
                      <div className={classes.withProductHuntContainer}>
                        <img
                          height="40"
                          alt="Reczee Screeno"
                          style={{ marginLeft: "8px", marginRight: "8px" }}
                          src={getStaticResourceUrl("/reczee-screeno-logo.svg")}
                        />
                      </div>
                      <div style={{ marginLeft: "auto", alignSelf: "center" }}>
                        <Button
                          startIcon={<AssignmentIcon color="primary" />}
                          onClick={() => {
                            navbarItemClick("GIVE FEEDBACK");
                            setOpenScreenoFeedbackModal(true);
                          }}
                          className={classes.corelinkColor}
                          disableRipple
                        >
                          <b>Share feedback</b>
                        </Button>
                        <ReczeeDialog
                          maxWidth="md"
                          title="Share feedback - Reczee Screeno"
                          description="We would love to hear how you are liking the product and if we can improve in any way."
                          open={openScreenoFeedbackModal}
                          onClose={() => setOpenScreenoFeedbackModal(false)}
                          content={
                            <div className={classes.productFeedbackContainer}>
                              <form
                                noValidate
                                onSubmit={handleScreenoFeedbackSubmit}
                              >
                                <FormControl
                                  component="fieldset"
                                  className={classes.radioGroupFormControl}
                                >
                                  <FormLabel
                                    component="legend"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    How likely are you to recommend Reczee
                                    Screeno to your friend or colleague?
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-label="score"
                                    name="score"
                                    required
                                  >
                                    {Array.from(
                                      { length: 10 },
                                      (_, i) => i + 1
                                    ).map((number) => {
                                      return (
                                        <FormControlLabel
                                          key={number}
                                          value={number.toString()}
                                          control={<Radio color="primary" />}
                                          label={number.toString()}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                                <ReczeeInputField
                                  title="Detailed feedback"
                                  name="description"
                                  multiline
                                  rows={6}
                                  fullWidth={true}
                                  variant="outlined"
                                  className={classes.fullWidthContainer}
                                />
                                <Button
                                  type="submit"
                                  variant="contained"
                                  size="medium"
                                  color="primary"
                                  className={classes.submitButton}
                                >
                                  Submit
                                </Button>
                              </form>
                            </div>
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classes.withProductHuntContainer}>
                        <img
                          height="40"
                          alt="Reczee ATS"
                          style={{ marginLeft: "8px", marginRight: "8px" }}
                          src={getStaticResourceUrl(
                            "/reczee-ats-dark-logo.svg"
                          )}
                        />
                      </div>
                      {companyPrivileged || agencyUser || hiringManager ? (
                        <div className={classes.rightSearchContainer}>
                          {atsAgentAllowed ? (
                            <>
                              <IconButton
                                color="primary"
                                className={classes.aiAgentButtonContainer}
                                disableFocusRipple
                                onClick={() => {
                                  setAtsAgentModalOpen(true);
                                  amplitudeLogger.logEvent("ATS_AGENT_MODAL", {
                                    from: "nav_click",
                                    state: "open",
                                  });
                                }}
                              >
                                <img
                                  src={getStaticResourceUrl(
                                    "/ats-agent-stars.svg"
                                  )}
                                  width="24"
                                  height="24"
                                  alt="star"
                                />
                              </IconButton>
                              <AtsAgentModal
                                atsAgentModalOpen={atsAgentModalOpen}
                                setAtsAgentModalOpen={setAtsAgentModalOpen}
                              />
                            </>
                          ) : (
                            ""
                          )}
                          <SearchBar />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={classes.sectionMobile}>
              {isTalentHub ? (
                <img
                  height="35"
                  alt="Reczee TalentHub"
                  style={{ marginRight: "auto", alignSelf: "center" }}
                  src={getStaticResourceUrl(
                    "/reczee_talenthub_navbar_white.svg"
                  )}
                />
              ) : (
                <img
                  height="35"
                  alt="Reczee"
                  style={{ marginRight: "auto", alignSelf: "center" }}
                  src={getStaticResourceUrl("/reczee_navbar_white.svg")}
                />
              )}
              <IconButton
                color="inherit"
                aria-haspopup="true"
                aria-label="show more"
                style={{ marginLeft: "auto" }}
                aria-controls={mobileMenuId}
                onClick={handleMobileMenuOpen}
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </ClickAwayListener>

      <Menu
        keepMounted
        id={mobileMenuId}
        anchorEl={mobileMoreAnchorEl}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {navbarList
          .filter((item) => item.label)
          .map((item, idx) => (
            <MenuItem
              key={idx}
              to={item.href}
              component={Link}
              style={{ minWidth: "120px" }}
              onClick={handleMenuClose(item.amplitudeParams)}
            >
              {item.label}
            </MenuItem>
          ))}
        {navbarList.length > 1 ? <Divider /> : null}
        {isLoggedIn() ? (
          <MenuItem onClick={handleProfileMenuOpen}>Account</MenuItem>
        ) : (
          ""
        )}
        {isTalentHub && !isLoggedIn() ? (
          <MenuItem
            component={Link}
            to={"/talenthub/register"}
            onClick={handleMobileMenuClose("CREATE MY PROFILE")}
          >
            Create my profile
          </MenuItem>
        ) : (
          ""
        )}
        {isTalentHub &&
        isTalentHubJobsPage &&
        !isTalentHubCandidateLoggedIn() ? (
          <MenuItem
            to={"/talenthub/post-job"}
            component={Link}
            onClick={handleMobileMenuClose("POST JOB")}
          >
            Post Job
          </MenuItem>
        ) : (
          ""
        )}
        {isTalentHub && !isTalentHubJobsPage && !isLoggedIn() ? (
          <MenuItem
            to={"/talenthub/jobs"}
            component={Link}
            onClick={handleMobileMenuClose("FIND JOBS")}
          >
            Find Jobs
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>

      <Menu
        id={menuId}
        keepMounted
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {getNavbarList("profileMenu", { ...userInfo }).map((item, idx) => (
          <MenuItem
            key={idx}
            to={item.href}
            component={Link}
            style={{ minWidth: "120px" }}
            onClick={handleMenuClose(item.amplitudeParams)}
          >
            {item.label}
          </MenuItem>
        ))}
        {isLoggedIn() ? (
          <div>
            <Divider />
            <MenuItem
              component={Link}
              to={
                isTalentHub && isTalentHubCandidateLoggedIn()
                  ? "/talenthub/login"
                  : "/login"
              }
              onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </div>
        ) : (
          ""
        )}
      </Menu>
    </div>
  );
}
