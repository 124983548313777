import {
  Button,
  CircularProgress,
  createMuiTheme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useParams } from "react-router-dom";
import { getRequest } from "../../utils/httpHelpers";
import ReczeeCompanyWhiteNavbar from "../commons/ReczeeCompanyWhiteNavbar";
import BenefitsAndPerks from "./BenefitsAndPerks";
import CareersPageSubheading from "./CareersPageSubheading";
import JobOpeningsList from "./JobOpeningsList";
import LifeAtCompany from "./LifeAtCompany";
import OurValues from "./OurValues";
import { ReczeeJobsFooter } from "./ReczeeJobsFooter";
import { deepMergeJSON } from "../../utils/commonHelpers";
import NinetyOneSquarefeet from "./NinetyOneSquarefeet";
import LevoCareersPage from "./LevoCareersPage";
import ReczeeDemoCareersPage from "./ReczeeDemoCareersPage";
import Farmako from "./Farmako";
import PiceCareersPage from "./PiceCareersPage";
// import DemoJobsPageAzira from "./DemoJobsPageAzira";

const getHeroSectionBackground = (color) => {
  return `radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,${color} 68% 70%,#0000 72%) 13px 13px/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,${color} 68% 70%,#0000 72%) 13px 13px/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,${color} 68% 70%,#0000 72%) 0 0/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,${color} 68% 70%,#0000 72%) 0 0/calc(2*13px) calc(2*13px), repeating-conic-gradient(#ffffff 0 25%,#0000 0 50%) 0 0/calc(2*13px) calc(2*13px), radial-gradient(#0000 66%,${color} 68% 70%,#0000 72%) 0 calc(13px/2)/13px 13px #ffffff`;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    heroSection: {
      justifyContent: "center !important",
      // background: getHeroSectionBackground(theme)
    },
    careersPageSection: {
      minHeight: "500px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
    },
    viewAllOpeningsButton: {
      padding: "8px 28px",
      marginTop: theme.spacing(1),
      fontSize: "16px",
      fontWeight: "bolder",
    },
    mainHeading: {
      marginTop: theme.spacing(10),
      fontWeight: "bolder",
      fontSize: "48px",
    },
    benefitsSection: {
      backgroundColor: theme.palette.secondary.main + "04",
    },
    jobOpeningsListContainer: {
      marginTop: "20px",
      paddingTop: theme.spacing(2),
      backgroundRepeat: "space",
    },
  };
});

const getThemeJson = (customStyle) => {
  return {
    palette: {
      primary: {
        main: customStyle.themePrimaryColor || "#08558b",
      },
      secondary: {
        main: customStyle.themeSecondaryColor || "#08558b",
      },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: customStyle.themePrimaryTextColor || "#fff",
          "&:hover": {
            backgroundColor: customStyle.themePrimaryColor + "dd" || "#08558b",
          },
        },
      },
      MuiSlider: {
        thumb: {
          color: customStyle.themePrimaryColor || "#08558b",
        },
        track: {
          color: customStyle.themePrimaryColor || "#08558b",
        },
        markLabelActive: {
          fontWeight: "lighter",
        },
      },
    },
  };
};

export default function CareersPage(props) {
  const classes = useStyles();
  const [companyDetails, setCompanyDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [themeJson, setThemeJson] = React.useState({});
  const [errorString, setErrorString] = React.useState(null);
  const { companySlug } = useParams();

  const scrollToOpenPositions = () => {
    const element = document.getElementById("job-openings-list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  React.useEffect(() => {
    setIsLoading(true);

    getRequest(
      `company/get-careers-page-details`,
      { company_slug: companySlug },
      (data) => {
        setCompanyDetails(data["company"]);
        setThemeJson(data["company"]["customStyle"]);
        document.title = `Careers at ${data["company"]["name"]}`;
        setIsLoading(false);
      },
      (errorMessages) => {
        setIsLoading(false);
        if (typeof errorMessages === "string") {
          setErrorString(errorMessages);
        } else {
          setErrorString(
            "Something went wrong! Please contact us if the issue persists."
          );
        }
      }
    );
    // eslint-disable-next-line
  }, [companySlug]);

  let gridContent = "";
  if (errorString) {
    gridContent = (
      <div style={{ marginTop: "40px", fontSize: "18px" }}>{errorString}</div>
    );
  } else if (isLoading || !companyDetails) {
    gridContent = (
      <div style={{ marginTop: "40px", fontSize: "18px" }}>
        <CircularProgress />
      </div>
    );
    // } else if (props.demoAzira) {
    //   gridContent = <DemoJobsPageAzira companySlug={companySlug} />;
  } else if (props.embed) {
    gridContent = <JobOpeningsList />;
  } else {
    gridContent = (
      <>
        <div>
          <ReczeeCompanyWhiteNavbar companyDetails={companyDetails} />
          {(() => {
            switch (companySlug) {
              case "91squarefeet":
                return <NinetyOneSquarefeet companyDetails={companyDetails} />;
              case "levo-ai":
                return <LevoCareersPage companyDetails={companyDetails} />;
              case "farmako":
                return <Farmako companyDetails={companyDetails} />;
              case "pice":
                return <PiceCareersPage companyDetails={companyDetails} />;
              case "reczee-demo":
                return (
                  <ReczeeDemoCareersPage companyDetails={companyDetails} />
                );
              default:
                return (
                  <>
                    <div
                      className={`${classes.heroSection} ${classes.careersPageSection} `}
                      style={{
                        background: getHeroSectionBackground(
                          companyDetails.customStyle.themeSecondaryColor
                        ),
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="h4"
                        className={classes.mainHeading}
                      >
                        Join our team!
                      </Typography>
                      <CareersPageSubheading companyDetails={companyDetails} />
                      <Typography
                        component="h2"
                        variant="h6"
                        className={classes.mainSubHeading}
                      >
                        {companyDetails.sub_heading}
                      </Typography>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.viewAllOpeningsButton}
                        size={"medium"}
                        onClick={scrollToOpenPositions}
                      >
                        View open positions
                      </Button>
                    </div>
                    <LifeAtCompany companyDetails={companyDetails} />
                    <OurValues companyDetails={companyDetails} />
                    <BenefitsAndPerks companyDetails={companyDetails} />
                  </>
                );
            }
          })()}
          <div
            id="job-openings-list"
            className={classes.jobOpeningsListContainer}
          >
            <JobOpeningsList />
          </div>
        </div>
        <ReczeeJobsFooter companyDetails={companyDetails} />
      </>
    );
  }

  return (
    <div className={classes.root}>
      <ThemeProvider
        theme={(baseTheme) => {
          return createMuiTheme(
            deepMergeJSON(baseTheme, getThemeJson(themeJson))
          );
        }}
      >
        {gridContent}
      </ThemeProvider>
    </div>
  );
}
