import { makeStyles } from "@material-ui/core";
import CandidateApplicationStateBadge from "../candidate/CandidateApplicationStateBadge";

const useStyles = makeStyles((theme) => ({
  searchGroupRow: {
    padding: "4px",
    width: "fit-content",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: (props) => {
        return props.noSelect ? "transparent" : "rgba(0, 0, 0, 0.04)";
      },
      borderRadius: "2px",
    },
  },
  candidateListName: {
    marginRight: theme.spacing(1),
    // fontSize: "12px",
  },
  firstCandidateRow: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  candidateListRequisition: {
    fontSize: "12px",
  },
  candidateListMetadata: {
    fontSize: "12px",
    color: "darkslategray",
  },
  secondCandidateRow: {
    fontSize: "12px",
  },
  stateBadgeContainer: {
    marginLeft: theme.spacing(1),
    "& .MuiChip-label": {
      fontSize: "10px",
      lineHeight: 1.5,
    },
  },
  requisitionTitle: {
    fontSize: "12px",
  },
}));

export default function CandidateApplicationPreview(props) {
  const classes = useStyles(props);
  const row = props.row;

  return (
    <div className={classes.searchGroupRow} onMouseDown={props.onMouseDown}>
      <div className={classes.firstCandidateRow}>
        <div className={classes.candidateListName}>{row.candidate.name}</div>
        <div className={classes.requisitionTitle}>{row.requisition_title}</div>
        <div className={classes.stateBadgeContainer}>
          <CandidateApplicationStateBadge {...row.state} small={true} />
        </div>
      </div>
      <div className={classes.secondCandidateRow}></div>
    </div>
  );
}
