import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  htmlContainer: {
    "& a": {
      color: theme.palette.primary.main,
    },
    "& li": {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
}));

export default function DangerousHtml(props) {
  const classes = useStyles();

  const containerRef = React.useRef(null);

  React.useEffect(() => {
    if (containerRef.current) {
      const anchors = containerRef.current.querySelectorAll("a");
      anchors.forEach((anchor) => {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer nofollow");
      });
    }
  }, []);

  return (
    <div
      className={
        props.className
          ? `${classes.htmlContainer} ${props.className}`
          : classes.htmlContainer
      }
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: props.content }}
    ></div>
  );
}
