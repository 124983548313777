import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import AtsAgentInputField from "./AtsAgentInputField";
import { postRequest } from "../../utils/httpHelpers";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { ReczeeAtsAgentChip } from "../commons/ReczeeAtsAgent";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontSize: "18px",
  },
  buttonsContainer: {
    marginTop: "12px",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  overallContainer: {
    display: "flex",
    flexDirection: "column",
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function SendCandidateEmailComponent({
  parsedPrompt,
  resetAction,
  onActionComplete,
}) {
  const classes = useStyles();
  const [candidateApplication, setCandidateApplication] = React.useState([]);
  const [textTemplate, setTextTemplate] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (parsedPrompt && parsedPrompt["fields"]["template_data"]) {
      setTextTemplate(parsedPrompt["fields"]["template_data"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["candidate_application_data"]) {
      setCandidateApplication([
        parsedPrompt["fields"]["candidate_application_data"],
      ]);
    }
  }, [parsedPrompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    postRequest(
      "ats-agent/perform-action",
      {},
      {
        action: "send_candidate_email",
        fields: {
          template_data: textTemplate,
          candidate_application_data: candidateApplication[0],
        },
        message: "",
      },
      (data) => {
        setIsSubmitting(false);
        const successData = {
          action: SendCandidateEmailHandler.ACTION,
          title: "Email sent successfully",
          description: (
            <>
              Email has been sent to{" "}
              <strong>
                {candidateApplication[0]?.candidate?.name || "the candidate"}
              </strong>{" "}
              using the <strong>{textTemplate.name}</strong> template.
            </>
          ),
          resetAction: resetAction,
          secondButton: (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              size={"medium"}
              target="_blank"
              component={Link}
              to={`/candidate-timeline/${candidateApplication[0].slug}`}
            >
              View Candidate Profile
            </Button>
          ),
        };
        onActionComplete(successData);
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
        }
      }
    );
  };

  return (
    <div className={classes.overallContainer}>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.actionDataContainer}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <AtsAgentInputField
                fieldName="candidate_application_data"
                fieldAttributes={{
                  value: candidateApplication,
                  label: "Candidate",
                  fullWidth: true,
                  onChange: (_, values) => {
                    setCandidateApplication(values);
                  },
                  dataFetchPath: "candidate-application/recent-list",
                  dataFetchParams: {},
                  single: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AtsAgentInputField
                fieldName="template_data"
                fieldAttributes={{
                  label: "Template",
                  fullWidth: true,
                  value: textTemplate,
                  onChange: (_, value) => {
                    setTextTemplate(value);
                  },
                  single: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.submit}
            size={"medium"}
            onClick={() => {
              resetAction(SendCandidateEmailHandler.ACTION, "Cancel");
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            size={"medium"}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Send Email"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default class SendCandidateEmailHandler extends BaseAgentHandler {
  static ACTION = "send_candidate_email";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<MailOutlineIcon color="primary" />}
        title={this.constructor.getHeaderText()}
      />
    );
  }

  static getHeaderText() {
    return "Send email to candidate";
  }

  renderBody() {
    return (
      <SendCandidateEmailComponent
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
        onActionComplete={this.onActionComplete}
      />
    );
  }

  static renderChip(setInputValue, setExamplePrompt, onChipSelect) {
    return (
      <ReczeeAtsAgentChip
        startIcon={<MailOutlineIcon style={{ color: "#ea4335" }} />}
        onClick={() => {
          setInputValue("Send an email to ");
          setExamplePrompt(
            <>
              Send an email to <b>Rohit</b> with <b>Screening rejection</b>{" "}
              template.
            </>
          );
          onChipSelect(this.ACTION);
        }}
        headerText={this.getHeaderText()}
      />
    );
  }

  renderExamplePrompt() {}
}
