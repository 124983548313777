import Slide from "@material-ui/core/Slide";

export const alertConfigParams = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  autoHideDuration: 4000,
  TransitionComponent: Slide,
};

export const CAREERS_PAGE_CDN_BASE_URL = "https://dtsvqkwuq3azr.cloudfront.net";

export const RECZEE_REF = "reczee_ref";

export const JOB_APPLICATION_SOURCE = "_src";

export const allowedPriorities = [
  { state: 127, label: "—" },
  { state: 0, label: "P0" },
  { state: 1, label: "P1" },
  { state: 2, label: "P2" },
  { state: 3, label: "P3" },
  { state: 4, label: "P4" },
  { state: 5, label: "P5" },
];

export const FREE_PLAN_ID = 2;

export const recruitmentMetrics = {
  candidate_application_stage_wise_counts: {
    label: "Stage-wise applications",
    scope: ["company", "requisition", "ta", "source"],
    implemented: true,
  },
  new_candidate_applications: {
    label: "New candidate applications",
    scope: ["company", "requisition", "ta", "source"],
    implemented: true,
  },
  // rejects_count: {
  //   label: "Rejects count",
  //   scope: ["company", "requisition"],
  //   implemented: false
  // },
  // company_vs_candidate_time: {
  //   label: "Company vs Candidate time (in days)",
  //   scope: ["company", "requisition", "ta"],
  //   implemented: true
  // },
  // waiting_on_time: {
  //   label: "Waiting on time",
  //   scope: ["company", "requisition", "ta"],
  //   implemented: true
  // },
  requisition_counts: {
    label: "Requisition counts",
    scope: ["company"],
    implemented: true,
  },
  // req_time_to_close: {
  //   label: "Time to close",
  //   scope: ["company"],
  //   implemented: false
  // },
  // time_to_submit_feedback: {
  //   label: "Time to submit feedback",
  //   scope: ["company", "requisition", "panel"],
  //   implemented: false
  // },
  reschedule_percentage: {
    label: "Reschedule percentage",
    scope: ["company", "requisition", "panel"],
    implemented: true,
  },
  interview_state_wise_counts: {
    label: "State-wise interviews",
    scope: ["company", "requisition", "ta", "panel"],
    implemented: true,
  },
  // candidate_nps: {
  //   label: "Candidate NPS",
  //   scope: ["company", "requisition", "ta"],
  //   implemented: false
  // },
  overall_interview_counts: {
    label: "Overall interview counts",
    scope: ["company", "requisition", "ta", "panel"],
    implemented: true,
  },
};

export const GRAPH_COLORS = [
  "#00A3BF",
  "#0052CC",
  "#00875A",
  "#FF991F",
  "#DE350B",
  "#5243AA",
  "#172B4D",
  "#E01E5B",
  "#e8912c",
  "#892e99",
  "#4a154b",
  "#0c4c8b",
  "#1e9bd1",
  "#007a5a",
  "#b0003a",
  "#006978",
  "#a53752",
  "#007562",
  "#00599d",
  "#2e7d32",
  "#c51162",
];

export const COMPANY_ROLES = {
  company_ta: "TA",
  company_panel: "Panel",
  company_hiring_manager: "Hiring manager",
  company_admin: "Superuser",
  company_employee: "Employee",
  agency: "Agency",
  reczee_admin: "Reczee Admin",
};

export const UNASSIGNED_USER_ID = 2;

export const analyticsGraphs = {
  candidates: [
    "candidate_application_stage_wise_counts",
    "new_candidate_applications",
  ],
  requisitions: ["requisition_counts"],
  interviews: ["overall_interview_counts", "interview_state_wise_counts"],
  // candidates: ["candidate_application_stage_wise_counts", "rejects_count", "company_vs_candidate_time", "waiting_on_time"],
  // requisitions: ["requisition_counts", "req_time_to_close"],
  // panels: ["time_to_submit_feedback", "reschedule_percentage", "interview_state_wise_counts"],
  // tas: ["company_vs_candidate_time", "waiting_on_time"],
  // ratings: ["candidate_nps"],
  // interviews: ["overall_interview_counts", "interview_state_wise_counts", "reschedule_percentage"]
};

export const candidateStateBadgeColors = {
  127: { backgroundColor: "#f1f1f1", color: "#212121" },
  0: { backgroundColor: "#f44336", color: "white" },
  1: { backgroundColor: "#ef5350", color: "white" },
  2: { backgroundColor: "#e57373", color: "white" },
  3: { backgroundColor: "#ef9a9a", color: "#212121" },
  4: { backgroundColor: "#ffcdd2", color: "#212121" },
  5: { backgroundColor: "#dacecd", color: "#212121" },
  unknown: { backgroundColor: "#CCCCCC", color: "#212121" },
  applied: { backgroundColor: "#FFDB94", color: "#212121" },
  sourced: { backgroundColor: "#FEC249", color: "#212121" },
  referred: { backgroundColor: "#F8A601", color: "#212121" },
  ta_screen: { backgroundColor: "#9B9EF3", color: "white" },
  hm_screen: { backgroundColor: "#6D73EE", color: "white" },
  assignment_shared: { backgroundColor: "#bd73b0", color: "white" },
  assignment_passed: { backgroundColor: "#702963", color: "white" },
  schedule_round: { backgroundColor: "#7DB8FF", color: "white" },
  round_scheduled: { backgroundColor: "#2A85F2", color: "white" },
  round_in_progress: { backgroundColor: "#0C56B0", color: "white" },
  schedule_debrief: { backgroundColor: "#D2517E", color: "white" },
  debrief_scheduled: { backgroundColor: "#DC799C", color: "white" },
  negotiate_offer: { backgroundColor: "#83E2B4", color: "#212121" },
  generate_offer: { backgroundColor: "#45D38F", color: "white" },
  offer_shared: { backgroundColor: "#27A568", color: "white" },
  joined: { backgroundColor: "#186741", color: "white" },
  screen_reject: { backgroundColor: "#FF7D7D", color: "white" },
  assignment_reject: { backgroundColor: "#ed6a6a", color: "white" },
  interview_reject: { backgroundColor: "#ED4C4C", color: "white" },
  salary_reject: { backgroundColor: "#f02528", color: "white" },
  offer_declined: { backgroundColor: "#d21011", color: "white" },
  candidate_dropoff: { backgroundColor: "#971616", color: "white" },
  not_responding: { backgroundColor: "#895629", color: "white" },
  duplicate: { backgroundColor: "#B06E35", color: "white" },
  on_hold: { backgroundColor: "#CAD9E3", color: "#212121" },
};

export const requisitionStateBadgeColors = {
  open: {
    backgroundColor: "#36AE7C",
    color: "white",
  },
  live: {
    backgroundColor: "#36AE7C",
    color: "white",
  },
  pending_approval: {
    backgroundColor: "#F9D923",
    color: "#212121",
  },
  rejected: {
    backgroundColor: "#EB5353",
    color: "white",
  },
  archived: {
    backgroundColor: "#ff9f9f",
    color: "#212121",
  },
};

export const echartsColorScheme = [
  "#2A85F2",
  "#0f5dbc",
  "#F8A601",
  "#27A568",
  "#186741",
  "#ED4C4C",
  "#C70202",
  "#CCCCCC",
];

// Don't increase this as backend is not able to concurrently
// parse things and time increases for every upload instead of
// processing happening in individual threads.
export const BULK_RESUME_BATCH_SIZE = 1;

export const BULK_RESUME_FILES_LIMIT = 20;

export const TAGS_PER_CANDIDATE_APPLICATION = 5;

export const NOTICE_PERIOD_OPTIONS = [
  "Immediate joiner",
  "15 days",
  "30 days",
  "45 days",
  "60 days",
  "90 days",
  "90+ days",
];

export const popularCurrencies = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "INR", value: "INR" },
  { label: "AUD", value: "AUD" },
  { label: "CAD", value: "CAD" },
  { label: "JPY", value: "JPY" },
  { label: "CNY", value: "CNY" },
  { label: "CHF", value: "CHF" },
  { label: "SGD", value: "SGD" },
];

export const popularTimezones = [
  {
    label: "Greenwich Mean Time",
    value: "Etc/Greenwich",
    abbreviation: "GMT",
  },
  {
    label: "Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
    abbreviation: "PST",
  },
  {
    label: "Mountain Time (US & Canada)",
    value: "America/Denver",
    abbreviation: "MST",
  },
  {
    label: "Central Time (US & Canada)",
    value: "America/Chicago",
    abbreviation: "CST",
  },
  {
    label: "Eastern Time (US & Canada)",
    value: "America/New_York",
    abbreviation: "EST",
  },
  {
    label: "British Summer Time",
    value: "Europe/London",
    abbreviation: "BST",
  },
  {
    label: "Central European Time",
    value: "Europe/Berlin",
    abbreviation: "CET",
  },
  {
    label: "India Standard Time",
    value: "Asia/Calcutta",
    abbreviation: "IST",
  },
  {
    label: "China Standard Time",
    value: "Asia/Shanghai",
    abbreviation: "CST",
  },
  {
    label: "Japan Standard Time",
    value: "Asia/Tokyo",
    abbreviation: "JST",
  },
  {
    label: "Australian Eastern Time",
    value: "Australia/Sydney",
    abbreviation: "AEST",
  },
];

export const PAYROLL_CYCLES = [
  { label: "Annually", value: "annually" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
];
