import React from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GreenTickWithAnimation from "../commons/GreenTickWithAnimation";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(3),
  },
  titleText: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  descriptionText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  buttonRow: {
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

export default function SuccessScreen({ successData }) {
  const { action, title, description, resetAction, secondButton } = successData;

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <GreenTickWithAnimation />

      {title && (
        <Typography variant="h5" component="h2" className={classes.titleText}>
          {title}
        </Typography>
      )}

      {description && (
        <Typography variant="body1" className={classes.descriptionText}>
          {description}
        </Typography>
      )}

      <div className={classes.buttonRow}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.submit}
          size={"medium"}
          onClick={() => {
            resetAction(action, "New Action");
          }}
        >
          New action
        </Button>

        {secondButton}
      </div>
    </Box>
  );
}
