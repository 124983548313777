import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import React from "react";
import amplitudeLogger, { SEARCH_PAGE } from "../../utils/amplitude";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { getRequest } from "../../utils/httpHelpers";
import CandidateApplicationStateBadge from "../candidate/CandidateApplicationStateBadge";
import CandidateApplicationDropdownRow from "./CandidateApplicationDropdownRow";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "auto",
    },
  },
  candidateListName: {
    marginRight: theme.spacing(1),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#616161",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "22ch",
      },
    },
  },
  searchResultContainer: {
    zIndex: 10,
    backgroundColor: "white",
    position: "absolute",
    right: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: "4px", // Same as standard paper component
    color: "black",
    maxWidth: "450px",
    minWidth: "360px",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
  },
  searchGroupHeading: {
    fontSize: "16px",
  },
  searchGroupRows: {
    paddingTop: "4px",
    fontSize: "14px",
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();
  const [searchResult, setSearchResult] = React.useState(null);
  const [searchModalOpen, setSearchModalOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const searchInputChange = (event) => {
    // @Todo(Raj): Debounce this in the future
    if (!event.target.value || event.target.value.length < 3) {
      setSearchResult(null);
      return;
    }
    getRequest(
      "search",
      { q: event.target.value },
      (data) => {
        setSearchResult(data);
      },
      (errorMessages, statusCode) => {
        enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
      }
    );
  };

  const handleSearchBlur = (event) => {
    // currentTarget is the parent element, relatedTarget is the clicked element
    setSearchModalOpen(false);
  };

  const navigateToPage = (slug) => () => {
    amplitudeLogger.logEvent("CANDIDATE_LINK_CLICK", {
      from: "results",
      page: SEARCH_PAGE,
    });
    window.location.href = `/candidate-timeline/${slug}`;
  };

  return (
    <div className={classes.root}>
      <Box border={1} borderColor="#BDBDBD" className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon color="primary" />
        </div>
        <InputBase
          placeholder="Search for candidates"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onFocus={() => {
            setSearchModalOpen(true);
          }}
          onBlur={handleSearchBlur}
          onChange={searchInputChange}
        />
      </Box>
      {searchModalOpen && searchResult ? (
        <div className={classes.searchResultContainer}>
          {searchResult &&
            searchResult.headings.map((heading, index) => {
              // if (!searchResult["content"][heading] || searchResult["content"][heading].length === 0) return null;
              return (
                <div key={index}>
                  {!searchResult["content"][heading] ||
                  searchResult["content"][heading].length === 0 ? (
                    "No matching records"
                  ) : (
                    <div className={classes.searchGroupRows}>
                      {searchResult["content"][heading].map((row, index) => {
                        return (
                          <CandidateApplicationDropdownRow
                            key={row.id}
                            row={row}
                            onMouseDown={navigateToPage(row.slug)}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
