import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import { isLoggedIn } from "../../utils/authHelpers";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { CurrentRelativeUrl } from "../../utils/urlHelpers";
import ReczeeNavbar from "../commons/ReczeeNavbar";
import ReczeeSidebar from "../commons/ReczeeSidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  main: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
  },
  upvoteButton: {
    backgroundColor: "white",
    marginLeft: "8px",
    color: "black",
    padding: "0px 6px",
    paddingRight: "4px",
    "& .MuiButton-endIcon": {
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const NavbarPrivateRoute = ({ component: Component, fullWidth, ...rest }) => {
  const classes = useStyles();
  const currentUrl = CurrentRelativeUrl();
  const { enqueueSnackbar } = useSnackbar();
  const setUserInfo = React.useContext(UserInfoContext)[1];
  const redirectToLogin = !isLoggedIn();
  const [productHuntViewed, setProductHuntViewed] = React.useState(false);

  React.useEffect(() => {
    if (sessionStorage.getItem("productHuntViewed") === "true") {
      setProductHuntViewed(true);
    }
  }, []);

  if (redirectToLogin) {
    // @Todo(Raj): This is logged twice for some reason
    // amplitudeLogger.logEvent("LOGOUT", {reason: "token_invalid"});
    // setUserInfo({});
  }

  // if (!!rest.allowCandidate !== isCandidateLoggedIn()) {
  //   // @Todo(Raj): This is logged too many times for some reason
  //   // amplitudeLogger.logEvent("LOGOUT", {reason: "candidate_cannot_access"});
  //   setUserInfo({});
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (redirectToLogin) {
          setUserInfo({});

          enqueueSnackbar(
            "Please log in to continue!",
            reczeeAlertParams("error")
          );
          if (rest.talenthub) {
            return (
              <Redirect push to={"/talenthub/login?returnUrl=" + currentUrl} />
            );
          } else {
            return <Redirect push to={"/login?returnUrl=" + currentUrl} />;
          }
        } else {
          return (
            <div className={classes.root}>
              <ReczeeSidebar />
              <div className={classes.main}>
                <CssBaseline />
                <ReczeeNavbar />
                <Container
                  maxWidth={fullWidth ? false : "xl"}
                  disableGutters={fullWidth ? true : false}
                  component="main"
                  className={classes.paper}
                >
                  <Component {...props} />
                </Container>
              </div>
            </div>
          );
        }
      }}
    />
  );
};

NavbarPrivateRoute.defaultProps = {
  fullWidth: false,
};

export default NavbarPrivateRoute;
