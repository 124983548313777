import React from "react";
import BaseAgentHandler from "./BaseAgentHandler";
import AtsAgentInputField from "./AtsAgentInputField";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { ReczeeAtsAgentChip } from "../commons/ReczeeAtsAgent";
import { postRequest } from "../../utils/httpHelpers";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AtsAgentModalHeader from "./AtsAgentModalHeader";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    alignItems: "center",
    fontSize: "18px",
  },
  buttonsContainer: {
    marginTop: "12px",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  overallContainer: {
    display: "flex",
    flexDirection: "column",
  },
  submit: { marginLeft: "4px", marginRight: "4px" },
}));

function InviteAgencyUserComponent({
  parsedPrompt,
  resetAction,
  onActionComplete,
}) {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [agencyName, setAgencyName] = React.useState("");

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (parsedPrompt && parsedPrompt["fields"]["email"]) {
      setEmail(parsedPrompt["fields"]["email"]);
    }
    if (parsedPrompt && parsedPrompt["fields"]["name"]) {
      setAgencyName(parsedPrompt["fields"]["name"]);
    }
  }, [parsedPrompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    postRequest(
      "ats-agent/perform-action",
      {},
      {
        action: "invite_agency_user",
        fields: {
          email: email,
          agency_name: agencyName,
        },
        message: "",
      },
      (data) => {
        setIsSubmitting(false);
        const successData = {
          action: InviteAgencyUserHandler.ACTION,
          title: "Agency invite sent successfully",
          description: (
            <>
              Invite has been sent to{" "}
              <strong>{agencyName || "The agency"}</strong> with email{" "}
              <strong>{email}</strong>.
            </>
          ),
          resetAction: resetAction,
          secondButton: (
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              size={"medium"}
              target="_blank"
              component={Link}
              to={`/company-settings/agencies`}
            >
              Manage Agencies
            </Button>
          ),
        };
        onActionComplete(successData);
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
        }
      }
    );
  };

  return (
    <div className={classes.overallContainer}>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.actionDataContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AtsAgentInputField
                fieldName="agency_name"
                fieldAttributes={{
                  value: agencyName,
                  fullWidth: true,
                  label: "Agency Name",
                  onChange: (e) => setAgencyName(e.target.value),
                  helperText:
                    "Candidate application source would be set to this",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AtsAgentInputField
                fieldName="email"
                fieldAttributes={{
                  value: email,
                  label: "Email",
                  fullWidth: true,
                  onChange: (e) => setEmail(e.target.value),
                  helperText: "For logging in to Reczee",
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.submit}
            size={"medium"}
            onClick={() => {
              resetAction(InviteAgencyUserHandler.ACTION, "Cancel");
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            size={"medium"}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Please wait..." : "Invite Agency"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default class InviteAgencyUserHandler extends BaseAgentHandler {
  static ACTION = "invite_agency_user";

  getModalHeader() {
    return (
      <AtsAgentModalHeader
        icon={<GroupAddIcon color="primary" />}
        title={this.constructor.getHeaderText()}
      />
    );
  }

  renderBody() {
    return (
      <InviteAgencyUserComponent
        parsedPrompt={this.parsedPrompt}
        resetAction={this.resetAction}
        onActionComplete={this.onActionComplete}
      />
    );
  }

  static getHeaderText() {
    return "Invite agency to Reczee";
  }

  static renderChip(setInputValue, setExamplePrompt, onChipSelect) {
    return (
      <ReczeeAtsAgentChip
        startIcon={<GroupAddIcon style={{ color: "#009951" }} />}
        onClick={() => {
          setInputValue("Invite agency ");
          setExamplePrompt(
            <>
              Invite agency <b>Rehiro</b> (<b>recruiter@rehiro.com</b>) to the
              account.
            </>
          );
          onChipSelect(this.ACTION);
        }}
        headerText={this.getHeaderText()}
      />
    );
  }

  renderExamplePrompt() {}
}
