import Badge from "@material-ui/core/Badge";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TuneIcon from "@material-ui/icons/Tune";
import SearchIcon from "@material-ui/icons/Search";
import WorkIcon from "@material-ui/icons/Work";
import {
  isAgencyUser,
  isCandidateLoggedIn,
  isCompanyAdmin,
  isCompanyPrivilegedUser,
  isHiringManager,
  isLoggedIn,
  isTalentHubCandidateLoggedIn,
  isScreenoPage,
  isTalentHubPage,
} from "./authHelpers";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { getAbsoluteUrl, getStaticResourceUrl } from "./urlHelpers";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const isTalentHubJobsPage = () => {
  if (window.location.pathname === "/talenthub/jobs") return true;
  let matchVal = window.location.pathname.match(/^\/talenthub\/.*\/jobs\/.*$/);
  return matchVal && matchVal.toString() === window.location.pathname;
};

const HOME = "home";
const CANDIDATES = "candidates";
const INTEGRATIONS = "integrations";
const SKILL_DASHBOARD = "skillDashboard";
const ANALYTICS = "analytics";
const CREDITS = "credits";
const SCREENO_CREDITS = "screenoCredits";
const SCREENO_JOBS = "screenoJobs";
const FIND_JOBS = "findJobs";
const REQUISITIONS = "requisitions";
const SMART_SEARCH = "smartSearch";
const RECRUITMENT_SETUP = "recruitmentSetup";
const CREATE_TALENTHUB_PROFILE = "createTalenthubProfile";
const POST_JOB = "postJob";
export const NOTIFICATIONS = "notifications";

const MANAGE_COMPANY = "manageCompany";
const PROFILE = "profile";
const VISIBILITY_SETTINGS = "visibilitySettings";
const MY_REFERRALS = "myReferrals";
const EMAIL_PREFERENCES = "emailPreferences";
const LOGOUT = "logout";

const navbarAllOptions = (props) => ({
  [HOME]: {
    href: isScreenoPage()
      ? "/screeno"
      : isTalentHubPage()
      ? isTalentHubCandidateLoggedIn()
        ? "/talenthub/candidate/dashboard"
        : "/talenthub/dashboard"
      : isCandidateLoggedIn()
      ? ""
      : "/",
    image: <HomeIcon />,
    labelNode: "Home",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "Logo",
      },
    },
  },
  [SKILL_DASHBOARD]: {
    href: "/talenthub/skill-dashboard",
    image: <CheckCircleIcon />,
    label: "Assess Skills",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "SKILLS",
      },
    },
  },
  [CANDIDATES]: {
    href: "/candidates",
    image: <PeopleAltRoundedIcon />,
    label: "Candidates",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "CANDIDATES",
      },
    },
  },
  [SMART_SEARCH]: {
    href: "/smart-search",
    image: <SearchIcon />,
    label: "Smart search",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "SMART SEARCH",
      },
    },
  },
  [ANALYTICS]: {
    href: "/analytics",
    image: <EqualizerIcon />,
    label: "Analytics",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "ANALYTICS",
      },
    },
  },
  [CREDITS]: {
    href: "/talenthub/credits",
    image: (
      <Badge
        color="secondary"
        invisible={!props.total_credits}
        badgeContent={props.total_credits}
        max={999}
      >
        <img
          alt=""
          src={getStaticResourceUrl("/gold-credit.svg")}
          style={{ height: "22px" }}
        />
      </Badge>
    ),
    label: "Credits",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "EARN CREDITS",
      },
    },
  },
  [SCREENO_CREDITS]: {
    href: "/screeno/credits",
    image: (
      <Badge
        color="secondary"
        invisible={!props.pending_credits}
        badgeContent={props.pending_credits}
        max={999}
      >
        <img
          alt=""
          src={getStaticResourceUrl("/new-gold-credit.svg")}
          style={{ height: "22px" }}
        />
      </Badge>
    ),
    label: "Credits",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "SCREENO CREDITS",
      },
    },
  },
  [INTEGRATIONS]: {
    href: "/screeno/integrations",
    label: "Integrations",
    image: <SwapHorizIcon fontSize="large" style={{ fontSize: "30px" }} />,
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "SCREENO INTEGRATIONS",
      },
    },
  },
  [SCREENO_JOBS]: {
    href: "/screeno/jobs",
    image: <WorkIcon />,
    label: "Jobs",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "SCREENO JOBS",
      },
    },
  },
  [FIND_JOBS]: {
    href: "/talenthub/jobs",
    image: <SearchIcon />,
    label: "Find jobs",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "FIND JOBS",
      },
    },
  },
  [REQUISITIONS]: {
    href: "/jobs",
    image: <WorkIcon />,
    label: "Jobs",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "JOBS",
      },
    },
  },
  [RECRUITMENT_SETUP]: {
    href: "/recruitment-setup",
    image: <TuneIcon />,
    labelNode: (
      <>
        Recruitment
        <br />
        Setup
      </>
    ),
    label: "Recruitment Setup",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "RECRUITMENT SETUP",
      },
    },
  },
  [CREATE_TALENTHUB_PROFILE]: {
    href: getAbsoluteUrl("app", "/talenthub/register"),
    image: <PersonAddIcon />,
    labelNode: (
      <>
        Create
        <br />
        My Profile
      </>
    ),
    label: "Create My Profile",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "CREATE MY PROFILE",
      },
    },
  },
  [POST_JOB]: {
    href: getAbsoluteUrl("app", "/talenthub/post-job"),
    image: <AddCircleIcon />,
    label: "Post Job",
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "POST JOB",
      },
    },
  },
  [NOTIFICATIONS]: {
    type: NOTIFICATIONS,
    href: "/notifications",
    label: "Notifications",
    image: (
      <>
        {props.unread_count && props.unread_count > 0 ? (
          <Badge badgeContent={props.unread_count} color="secondary">
            <NotificationsIcon />
          </Badge>
        ) : (
          <NotificationsIcon />
        )}
      </>
    ),
    amplitudeParams: {
      eventName: "NAVBAR_ITEM_CLICK",
      eventProperties: {
        value: "NOTIFICATIONS",
      },
    },
  },
});

const menubarAllOptions = (props) => ({
  [MANAGE_COMPANY]: {
    href: "/company-settings",
    label: "Manage company",
    amplitudeParams: {
      eventName: "PROFILE_MENU_CLICK",
      eventProperties: {
        value: "Manage company",
      },
    },
  },
  [PROFILE]: {
    href:
      isTalentHubPage() && isTalentHubCandidateLoggedIn()
        ? "/talenthub/dashboard"
        : isCandidateLoggedIn()
        ? "/"
        : `/users/${props.userId}`,
    label: "Profile",
    amplitudeParams: {
      eventName: "PROFILE_MENU_CLICK",
      eventProperties: {
        value: "Profile",
      },
    },
  },
  [VISIBILITY_SETTINGS]: {
    href: "/talenthub/visibility-settings",
    label: "Visibility settings",
    amplitudeParams: {
      eventName: "PROFILE_MENU_CLICK",
      eventProperties: {
        value: "Visibility settings",
      },
    },
  },
  [MY_REFERRALS]: {
    href: "/talenthub/referrals",
    label: "My referrals",
    amplitudeParams: {
      eventName: "PROFILE_MENU_CLICK",
      eventProperties: {
        value: "My referrals",
      },
    },
  },
  [EMAIL_PREFERENCES]: {
    href: "/talenthub/email-preferences",
    label: "Email preferences",
    amplitudeParams: {
      eventName: "PROFILE_MENU_CLICK",
      eventProperties: {
        value: "Email preferences",
      },
    },
  },
  [LOGOUT]: {
    // This is tricky, how to handle post callback
  },
});

export function getNavbarList(kind, props) {
  let roles = props.roles;
  let finalNavOptions = [];
  let finalMenuOptions = [];
  const isTalentHub = isTalentHubPage();
  const isScreeno = isScreenoPage();
  const isSmartSearchOnly = props.feature_details
    ? props.feature_details.company.is_smart_search_only
    : false;

  const smartSearchEnabled = props.feature_details
    ? props.feature_details.company.resume_smart_search
    : false;

  if (isTalentHub) {
    if (isTalentHubCandidateLoggedIn()) {
      finalNavOptions = [HOME, FIND_JOBS, CREDITS, SKILL_DASHBOARD];
      finalMenuOptions = [PROFILE, MY_REFERRALS, EMAIL_PREFERENCES];
    } else {
      if (isCompanyPrivilegedUser(roles)) {
        finalNavOptions = [HOME];
        finalMenuOptions = [PROFILE];
      } else {
        if (isTalentHubJobsPage) {
          finalNavOptions = [HOME];
          finalMenuOptions = [];
        } else {
          finalNavOptions = [HOME];
          finalMenuOptions = [];
        }
      }
    }
  } else if (isScreeno) {
    finalNavOptions = [HOME, SCREENO_JOBS, SCREENO_CREDITS, INTEGRATIONS];
    finalMenuOptions = [PROFILE];
  } else {
    if (isLoggedIn()) {
      if (isSmartSearchOnly) {
        finalNavOptions = [HOME, SMART_SEARCH];
        finalMenuOptions = [PROFILE];
      } else if (isCompanyAdmin(roles)) {
        finalNavOptions = [
          HOME,
          REQUISITIONS,
          CANDIDATES,
          RECRUITMENT_SETUP,
          NOTIFICATIONS,
          ANALYTICS,
        ];
        if (smartSearchEnabled) finalNavOptions.push(SMART_SEARCH);
        finalMenuOptions = [PROFILE, MANAGE_COMPANY];
      } else if (isCompanyPrivilegedUser(roles)) {
        finalNavOptions = [
          HOME,
          REQUISITIONS,
          CANDIDATES,
          RECRUITMENT_SETUP,
          NOTIFICATIONS,
          ANALYTICS,
        ];
        if (smartSearchEnabled) finalNavOptions.push(SMART_SEARCH);
        finalMenuOptions = [PROFILE];
      } else if (isAgencyUser(roles)) {
        finalNavOptions = [HOME, NOTIFICATIONS];
        finalMenuOptions = [PROFILE];
      } else if (isCandidateLoggedIn()) {
        finalNavOptions = [HOME];
        finalMenuOptions = [PROFILE];
      } else if (isHiringManager(roles)) {
        finalNavOptions = [HOME, REQUISITIONS, CANDIDATES, NOTIFICATIONS];
        finalMenuOptions = [PROFILE];
      } else {
        finalNavOptions = [HOME, NOTIFICATIONS];
        finalMenuOptions = [PROFILE];
      }
    } else {
      finalNavOptions = [];
      finalMenuOptions = [];
    }
  }

  if (kind === "navbar") {
    return finalNavOptions.map((itemName) => navbarAllOptions(props)[itemName]);
  } else {
    return finalMenuOptions.map(
      (itemName) => menubarAllOptions(props)[itemName]
    );
  }
}
