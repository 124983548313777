import SpecificScheduleHandler from "./SpecificScheduleHandler";
import SendCandidateEmailHandler from "./SendCandidateEmailHandler";
import SelfScheduleHandler from "./SelfScheduleHandler";
import InviteUserHandler from "./InviteUserHandler";
import InviteAgencyUserHandler from "./InviteAgencyUserHandler";
import FilterCandidateSpreadsheetHandler from "./FilterCandidateSpreadsheetHandler";
import GenericHandler from "./GenericHandler";

export default function getAtsAgentHandler(action) {
  switch (action) {
    case SpecificScheduleHandler.ACTION:
      return SpecificScheduleHandler;
    case SendCandidateEmailHandler.ACTION:
      return SendCandidateEmailHandler;
    case SelfScheduleHandler.ACTION:
      return SelfScheduleHandler;
    case InviteUserHandler.ACTION:
      return InviteUserHandler;
    case InviteAgencyUserHandler.ACTION:
      return InviteAgencyUserHandler;
    case FilterCandidateSpreadsheetHandler.ACTION:
      return FilterCandidateSpreadsheetHandler;
    case GenericHandler.ACTION:
      return GenericHandler;
    default:
      throw new Error("Not implemented " + action);
  }
}
