import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  suggestions: {
    borderRadius: "12px",
    padding: "4px 12px",
    fontSize: "12px",
    color: "#555555",
    textTransform: "none",
  },
}));

export function ReczeeAtsAgentChip(props) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      startIcon={props.startIcon}
      className={classes.suggestions}
      onClick={props.onClick}
    >
      {props.headerText}
    </Button>
  );
}
